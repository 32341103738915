/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';

import PropTypes from "prop-types";
import "./expand.scss";

import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import {Trans} from "react-i18next";


export default class DonePercentExpandComponent extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    let content;
    const parentMode = this.props.parentMode;
    const donePercentExist = !Number.isNaN(this.props.donePercent);
    const donePercent = Math.trunc(this.props.donePercent * 100);

    let textId;
    if (donePercentExist && parentMode) textId = "tracker.expand.done_percent_parent";
    else if (donePercentExist && !parentMode) textId = "tracker.expand.done_percent";
    else if (!donePercentExist && parentMode) textId = "tracker.expand.done_percent_no_data_parent";
    else if (!donePercentExist && !parentMode) textId = "tracker.expand.done_percent_no_data";


    content =
      <div className="expand-row-container">
        <div className="expand-row-image" id="done-percent-expand-row-image"
             style={{backgroundColor: this.props.color}}/>
        <span className="expand-row-text">
          <Trans i18nKey={textId} values={{donePercent: donePercent}}>
            N% done
          </Trans>
        </span>
      </div>
    ;

    return content;
  }

}

DonePercentExpandComponent.propTypes = {
  donePercent: PropTypes.number,
  color: PropTypes.string,
  parentMode: PropTypes.bool
};
