import React from "react";
import axios from 'axios';
import {TRELLO_API_KEY, TRELLO_POWER_UP_NAME} from "../utils/Const";
import RepeatService from "./RepeatService";
import moment from "moment";
import {chunk} from "../utils/ArrayUtil";
import BoardStatusService from "./BoardStatusService";
import SyncRepeatCardService from "./SyncRepeatCardService";
import {useTranslation} from 'react-i18next';
import i18next from "i18next";
import * as Sentry from "@sentry/browser";

export default class TrelloService {
  static BASE_URL = "https://api.trello.com/1";
  static globalTrello;


  openCard(cardId) {
    this.trello.showCard(cardId);
  }

  /**
   * open in new window
   * @param cardId
   */
  openCardViaUrl(cardId) {
    window.open(`https://trello.com/c/${cardId}`)
  }

  setRepeatCardData(data, cardId) {
    let jsonData = JSON.stringify(data, (key, value) => {
      // Store a set in the form a list that can be used to recreate it
      if (["daysOfWeek", "daysOfMonth"].includes(key)) {
        return Array.from(value);
      }

      return value;
    });
    return this.trello.set(cardId ? cardId : "card", "private", "repeat-card-data", jsonData)
  }

  getRepeatCardData(cardId) {
    return this.trello.get(cardId ? cardId : "card", "private", "repeat-card-data", null)
      .then(str => {
        if (str !== null) {
          let parsedObj = JSON.parse(str, (key, value) => {
            if (["daysOfWeek", "daysOfMonth"].includes(key)) {
              return new Set(value)
            }
            if (["time"].includes(key)) {
              return new Date(value)
            }
            return value
          });
          return parsedObj
        } else {
          return null
        }
      })
  }

  getRepeatCards() {
    return this.trello.cards("id")
      .then(data => {
        return Promise.all(data.map(card => {
          return this.getRepeatCardData(card.id)
            .then(repeatData => {
              return {
                id: card.id,
                repeatData: repeatData
              }
            })
        }))
      })
      .then(cards => {
        return cards.filter(card => {
          return card.repeatData
        })
      })
  }


  authorizeAdmin() {
    let results = {};
    return this.trello.getRestApi()
      .authorize({scope: 'read,write,account', expiration: "never"})
      .then(token => {
        //check that token alive
        return axios.get(TrelloService.BASE_URL + `/members/me?token=${token}&key=${TRELLO_API_KEY}`)
          .then(_=>{return Promise.resolve(token)})
      })
      .catch(e => {
        if (e.response && e.response.status && e.response.status === 401) {
          return this.trello.getRestApi().clearToken() //remove stored wrong token
            .then(_=>{
              return this.trello.getRestApi().authorize({scope: 'read,write,account', expiration: "never"}) //request new token
            })
        } else {
          return Promise.reject(e)
        }
      })
      .then(token => {
        results.token = token;
        return this.trello.set("board", "private", "adminToken", token)
      })
      .then(_ => {
        return this.getAdmin(results.token)
      })

  }

  getCardsByIds(ids) {
    return this.getToken()
      .then(token => {
        let idsSet = chunk(ids, 10);//10 max
        let urlsSet = idsSet.map(ids => {
          return encodeURIComponent(ids.map(id => `/cards/${id}?fields=name%2CidList`).join(","))
        });
        return Promise.all(urlsSet.map(urls => {
          return axios.get(TrelloService.BASE_URL + `/batch/?urls=${urls}&token=${token}&key=${TRELLO_API_KEY}`)
            .then(resp => {
              return resp.data
            })
        }))
      })
      .then(resultsSet => {
        return resultsSet.flat().map(resp => {
          return resp["200"]
        }).filter(it => it); //remove empty elements
      })
  }

  getAdmin(token = null) {
    //console.log("FFF token param: " + token);
    let results = {};
    let tokenPromise = token == null ? this.getToken() : Promise.resolve(token);
    return tokenPromise
      .then(token => {
        results.token = token;
        return axios.get(TrelloService.BASE_URL + `/token/${token}/member?token=${token}&key=${TRELLO_API_KEY}`)
      })
      .then(resp => {
        return resp.data
      })
      .then(user => {
        user.token = results.token;
        return user
      })
  }

  isAuthorized() {
    return this.getToken()

  }

  getToken() {
    return this.trello.get("board", "private", "adminToken")
      .then(t => {
        if (process.env.NODE_ENV !== "production") {
          console.log("Token: " + t);
        }
        return t;
      })
  }

  getHTSettings() {
    return this.trello.get("board", "private", "HTSettings")
      .then(str => {
        if (str) {
          return JSON.parse(str)
        } else {
          return {
            doneListIds: [], //old field, now this field stored on server, this not deleted for migration in InitComponent
            aggregationType: "day"
          };
        }
      })
  }

  saveHTSettings(htSettings) {
    let jsonData = JSON.stringify(htSettings);
    return this.trello.set("board", "private", "HTSettings", jsonData)
      .then(r => {
        return htSettings
      })
  }

  isDoneListIdsMigrated() {
    return this.trello.get("board", "private", "DoneListIdsMigrated")
  }

  setDoneListIdsMigrated(migrated) {
    return this.trello.set("board", "private", "DoneListIdsMigrated", migrated)
  }

  getLastDestinationListId() {
    return this.trello.get("board", "shared", "LastDestinationListId")
  }

  setLastDestinationListId(listId) {
    return this.trello.set("board", "shared", "LastDestinationListId", listId)
  }

  getCloneCardData() {
    return this.trello.get("card", "private", "cardClone")
      .then(str => {
        if (str) {
          return JSON.parse(str);
        } else {
          return null;
        }
      })
  }

  saveCloneCardData(cloneData) {
    let jsonData = JSON.stringify(cloneData);
    return this.trello.set("card", "private", "cardClone", jsonData)
      .then(r => {
        return cloneData
      })
  }

  deleteRepeatCardData() { //old
    return this.trello.remove("card", "private", "repeat-card-data")
  }

  refreshIframeSize(selector) {
    this.trello.sizeTo(selector ? selector : '#content').done();
  }

  closePopup() {
    this.trello.closePopup()
  }

  getContext() {
    return this.trello.getContext();
  }

  saveBoardStatus(boardStatus) {
    let jsonData = JSON.stringify(boardStatus);
    return this.trello.set("board", "private", "board-status", jsonData)
      .then(r => {
        return boardStatus
      })
  }

  deleteBoardStatus() {
    return this.trello.set("board", "private", "board-status", null)
  }

  getBoardStatus() {
    return this.trello.get("board", "private", "board-status")
      .then(str => {
        if (str) {
          return JSON.parse(str)
        } else {
          return null
        }
      })
  }

  getAllBoardLists() {
    return this.getToken()
      .then(token => {
        let boardId = this.trello.getContext().board;
        return axios.get(TrelloService.BASE_URL + `/boards/${boardId}/lists/all?token=${token}&key=${TRELLO_API_KEY}`)
      })
      .then(resp => {
        return resp.data
      })
  }

  openPurchaseModal(callback) {
    this.trello.modal({
      // the url to load for the iframe
      url: window.location.origin + '/trello/purchase',
      // optional color for headerRef chrome
      accentColor: "#F5F5F5",//'#F2D600',
      // initial height for iframe
      height: 1100, // not used if fullscreen is true
      // whether the modal should stretch to take up the whole screen
      fullscreen: false,
      // optional function to be called if user closes modal (via `X` or escape, etc)
      callback: callback,
      // optional title for headerRef chrome
      title: i18next.t("trello.payment_window_title"),
    })
  }

  openPurchaseSettingsModal(callback) { //todo_rfc duplicate with method openPurchaseModal
    this.trello.modal({
      // the url to load for the iframe
      url: window.location.origin + '/trello/purchaseSettings',
      // optional color for headerRef chrome
      accentColor: "#F5F5F5",
      // initial height for iframe
      height: 1100, // not used if fullscreen is true
      // whether the modal should stretch to take up the whole screen
      fullscreen: false,
      // optional function to be called if user closes modal (via `X` or escape, etc)
      callback: callback,
      // optional title for headerRef chrome
      title: i18next.t("trello.payment_settings_window_title"),
    })
  }

  closeModal() {
    this.trello.closeModal()
  }


  constructor(trello) {

    let ctx = trello.getContext();
    Sentry.setTag("Board", ctx.board);
    Sentry.setTag("Card", ctx.card);
    Sentry.setTag("Capability_Command", ctx.command);
    Sentry.setUser({"id": ctx.member});

    window.ga('set', 'userId', ctx.board);
    window.ga('set', 'dimension1', ctx.board);
    window.ga('set', 'dimension2', "0"); //версия всего приложения для AB тестов //реальная устанавливается после получения BoardStatus

    this.trello = trello
  }


  static getIframeInstance() {
    let trello = window.TrelloPowerUp.iframe({
      appKey: TRELLO_API_KEY,
      appName: TRELLO_POWER_UP_NAME
    });
    return new TrelloService(trello);
  }

  static getGlobalInstance() {
    return new TrelloService(TrelloService.globalTrello);
  }

  static initializeGlobal() {
    //const { transl, i18n } = useTranslation();

    let promise;
    if (!TrelloService.globalTrello) {
      promise = new Promise((resolve, reject) => {
        TrelloService.globalTrello = TrelloPowerUp.initialize({
            // NOTE about asynchronous responses
            // If you need to make an asynchronous request or action before you can reply to Trello
            // you can return a Promise (bluebird promises are included at TrelloPowerUp.Promise)
            // The Promise should resolve to the object type that is expected to be returned
            'authorization-status': function (t, options) {
              let trelloService = new TrelloService(t);
              let repeatService = RepeatService.getInstance(trelloService);
              let boardStatusService = new BoardStatusService(trelloService, repeatService);
              // return a promise that resolves to the object with
              // a property 'authorized' being true/false
              // you can also return the object synchronously if you know
              // the answer synchronously
              return boardStatusService.getBoardStatusLocal()
                .then(status => {
                  return {authorized: status.configured}
                });
            },
            'show-authorization': function (t, options) {
              // return what to do when a user clicks the 'Authorize Account' link
              // from the Power-Up gear icon which shows when 'authorization-status'
              // returns { authorized: false }
              // in this case we would open a popup
              return t.popup({
                title: i18next.t("trello.auth_window_title"),
                url: window.location.origin + '/trello/auth',
                height: 150
              });
            },
            'remove-data': function (t) {
              // load the data from Trello quickly first in case we need
              // to use any of it to cleanup on our side
              let trelloService = new TrelloService(t);
              window.ga('send', 'event', 'init', 'remove-data');
              let repeatService = RepeatService.getInstance(trelloService);
              let boardStatusService = new BoardStatusService(trelloService, repeatService);
              let syncRepeatCardService = new SyncRepeatCardService(trelloService, repeatService, boardStatusService);
              return repeatService.deactivateBoard(trelloService.getContext().board)
                .then(_ => {
                  return boardStatusService.deleteBoardStatusLocal()
                })
                .then(_ => {
                  return syncRepeatCardService.syncRepeatCommandEnabled()
                })
            },
            'on-enable': function (t) {
              let trelloService = new TrelloService(t); //set User ID insight
              window.ga('send', 'event', 'init', 'enable');
            },
            'on-disable': function (t) {
              // load the data from Trello quickly first in case we need
              // to use any of it to cleanup on our side
              let trelloService = new TrelloService(t);
              window.ga('send', 'event', 'init', 'disable');
              let repeatService = RepeatService.getInstance(trelloService);
              let boardStatusService = new BoardStatusService(trelloService, repeatService);
              return repeatService.deactivateBoard(trelloService.getContext().board)
                .then(_ => {
                  return boardStatusService.deleteBoardStatusLocal()
                })
                .catch(e => {
                  return boardStatusService.deleteBoardStatusLocal()
                })
            },
            'board-buttons': function (t, options) {
              TrelloService.globalTrello = t;
              const trelloService = TrelloService.getGlobalInstance();
              resolve(trelloService);
              window.ga('send', 'pageview', "/board_buttons_embeded");
              trelloService.getToken()
                .then(t => {
                  let page = t ? "/authorized_board_buttons_embeded" : "/unauthorized_board_buttons_embeded";
                  window.ga('send', 'pageview', page);
                });
              let resultButtons = [];
              resultButtons.push({
                icon: window.location.origin + HABIT_TRACKER_ICON,
                text: i18next.t("trello.ht_board_btn"),
                callback: t => {
                  t.modal({
                    url: window.location.origin + '/trello/habitTracker?react_perf',
                    accentColor: "#F5F5F5",
                    fullscreen: true,
                    title: i18next.t("trello.ht_window_title"),
                  })
                }
              });
              return resultButtons
            },
            'card-buttons': function (trello, options) {
              return [{
                // usually you will provide a callback function to be run on button click
                // we recommend that you use a popup on click generally
                icon: window.location.origin + REPEAT_ICON, // don't use a colored icon here
                text: i18next.t("trello.repeat_card_btn"),
                callback: cardButtonCallback
              }];
            },
            'card-detail-badges': function (t, options) {
              return cardDetailBadges(t);
            },
            'card-badges': function (t, opts) {
              return cardBages(opts, t);
            },
            'show-settings':

              function (t, options) {
                // when a user clicks the gear icon by your Power-Up in the Power-Ups menu
                // what should Trello show. We highly recommend the popup in this case as
                // it is the least disruptive, and fits in well with the rest of Trello's UX
                return t.popup({
                  title: i18next.t("trello.settings_window_title"),
                  items: [{
                    text: i18next.t("trello.payment_settings_item"),
                    callback: function (t, opts) {
                      let trelloService = new TrelloService(t);
                      trelloService.openPurchaseSettingsModal()
                    }
                  }, {
                    text: i18next.t("trello.auth_settings_item"),
                    callback: function (t, opts) {
                      t.popup({
                        title: i18next.t("trello.auth_settings_window_title"),
                        url: window.location.origin + '/trello/authSettings',
                        height: 150
                      })
                    }
                  }, {
                    text: i18next.t("trello.website_settings_item"),
                    callback: function (t, opts) {
                      window.ga('send', 'pageview', "/website_external");
                      window.open("https://upgradebrain.ru", '_blank');
                    }
                  }, {
                    text: i18next.t("trello.submit_issue_settings_item"),
                    callback: function (t, opts) {
                      window.ga('send', 'pageview', "/submit_issue_external");
                      window.open("mailto:support@upgradebrain.ru", '_blank');
                    }
                  }]
                });
              }

            ,
          },
          {
            appKey: TRELLO_API_KEY,
            appName:
            TRELLO_POWER_UP_NAME
          }
        )
        ;
      })
      ;

    }
    else {
      promise = new Promise((resolve, reject) => {
        resolve(TrelloService.getGlobalInstance())
      })
    }
    return promise
  }


}

const REPEAT_ICON = '/public/repeat.svg';
const NOTIFICATION_ICON = '/public/notification.svg';
const COPY_ICON = '/public/copy.svg';
const HABIT_TRACKER_ICON = '/public/habit_tracker.svg';


var cardBages = (opts, t) => {


  let shortDateFormat = "dd D MMM";
  let longDateFormat = "D MMM YYYY";

  let trelloService = new TrelloService(t);
  let repeatService = RepeatService.getInstance(trelloService);


  let cardId = t.getContext().card;
  return Promise.all([
    trelloService.getRepeatCardData(),
    trelloService.getCloneCardData()
      .then(data => {
        if (data) return data;
        else return repeatService.getCardClone(cardId)
          .then(data => {
            data.exist = true;
            return data;
          })
          .catch(e => {
            if (e.response.status === 404) {
              return {
                exist: false
              }
            } else {
              throw e;
            }
          })
          .then(data => {
            return trelloService.saveCloneCardData(data)
          })
      })

  ])
    .then(values => {
      let bages = [];
      const repeatData = values[0];
      const cloneData = values[1];
      if (repeatData && repeatData.enabled) {
        bages.push({
          icon: window.location.origin + REPEAT_ICON,
        });
        if (repeatData.notify && repeatData.notify.enabled) {
          bages.push({
            icon: window.location.origin + NOTIFICATION_ICON,
          });
        }
      }
      if (cloneData && cloneData.exist) {
        let date = moment(cloneData.date);
        let format = date.year() == moment().year() ? shortDateFormat : longDateFormat;
        let text = cloneData.notificationEnabled ? null : moment(cloneData.date).format(format);
        bages.push({
          icon: window.location.origin + COPY_ICON,
          text: text
        })
      }
      return bages;
    })
  /* .catch(e => {
     console.log("AAA badges e: " + e.toString());
     throw e;
   });*/
};

var cardDetailBadges = function (t) {
  let trelloService = new TrelloService(t);
  let repeatService = RepeatService.getInstance(trelloService);
  let shortDateFormat = "dd D MMM hh:mm A";
  let longDateFormat = "D MMM YYYY hh:mm A";

  return t.card('id')
    .get('id')
    .then(cardId => {

      return Promise.all([
        repeatService.getCardClone(cardId)
          .catch(e => {
            return null
          }),
        trelloService.getRepeatCardData(cardId)
      ])

    })
    .then(cardData => {
      let cardClone = cardData[0];
      let repeatData = cardData[1];

      let bages = [];
      if (cardClone) {
        let date = moment(cardClone.date);
        let format = date.year() == moment().year() ? shortDateFormat : longDateFormat;
        bages.push(
          {
            title: i18next.t("trello.repeat_date_card_label"),
            text: date.format(format),
            callback: t => { // function to run on click
              t.showCard(cardClone.parentCardId)
            }
          })
      }
      if (repeatData && repeatData.enabled) {
        return repeatService.getNextDates(repeatData.repeatCommand.cron)
          .then(dates => {
            bages.push({
              title: i18next.t("trello.next_repeat_card_label"),
              icon: window.location.origin + REPEAT_ICON,
              text: moment(dates[0]).format(shortDateFormat),
              callback: t => { // function to run on click
                t.popup({
                  title: i18next.t("trello.repeat_window_title"),
                  url: window.location.origin + '/trello/repeat',
                  height: 300 // we can always resize later, but if we know the size in advance, its good to tell Trello
                });
              }
            });
            return bages
          })
      } else {
        return bages
      }
    })

};


var
  cardButtonCallback = function (t) {

    // we could provide a standard iframe popup, but in this case we
    // will let Trello do the heavy lifting
    return t.popup({
      title: i18next.t("trello.repeat_window_title"),
      url: window.location.origin + '/trello/repeat',
      height: 357 // we can always resize later, but if we know the size in advance, its good to tell Trello
    });
    /*t.popup({
    title: 'Popup Search Example',
    items: items, // Trello will search client-side based on the text property of the items
    search: {
      count: 5, // How many items to display at a time
      placeholder: 'Search National Parks',
      empty: 'No parks found'
    }
  });*/

    // in the above case we let Trello do the searching client side
    // but what if we don't have all the information up front?
    // no worries, instead of giving Trello an array of `items` you can give it a function instead
    /*
    return t.popup({
      title: 'Popup Async Search',
      items: function(t, options) {
        // use options.search which is the search text entered so far
        // and return a Promise that resolves to an array of items
        // similar to the items you provided in the client side version above
      },
      search: {
        placeholder: 'Start typing your search',
        empty: 'Huh, nothing there',
        searching: 'Scouring the internet...'
      }
    });
    */
  };
