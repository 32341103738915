/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';

import PropTypes from "prop-types";
import "./expand.scss";
import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import DisabledParentExpandComponent from "./DisabledParentExpandComponent";
import CardCountExpandComponent from "./CardCountExpandComponent";
import DoneCountExpandComponent from "./DoneCountExpandComponent";
import DonePercentExpandComponent from "./DonePercentExpandComponent";
import LastDonePercentExpandComponent from "./LastDonePercentExpandComponent";
import SyntheticCountExpandComponent from "./SyntheticCountExpandComponent";


export default class RangeExpandComponent extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    let content;
    let range = this.props.range;
    let synthetic = range.syntheticCount === 0 ? null : <div>
      <SyntheticCountExpandComponent syntheticCount={range.syntheticCount}/>
      {rowSpace()}
    </div>;
    let title = (range.aggregationType === "day" ? range.start.format("dddd, ") : range.start.format("D - "))
      + range.end.clone().subtract(1, 'day').format("D MMMM YYYY");
    content =
      <div className="expand-container">
        <span className="expand-title">{title}</span>
        <CardCountExpandComponent cardCount={range.cardCount}/>
        {rowSpace()}
        {synthetic}
        <DoneCountExpandComponent doneCount={range.doneCount}/>
        {rowSpace()}
        <DonePercentExpandComponent parentMode={false} color={range.color} donePercent={range.donePercent}/>
      </div>
    ;

    return content;
  }

}

function rowSpace(){
  return <div className="space-btw-expand-row"/>
}

RangeExpandComponent.propTypes = {
  range: PropTypes.object.isRequired,
};
