/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import TrelloService from "../../service/TrelloService";

import PropTypes from "prop-types";
import "./habitTracker.scss";
import CellHabitComponent from "./CellHabitComponent";
import update from "immutability-helper";
import {makeCancelable} from "../../utils/PromiseUtil";
import CheckboxComponent from "../common/CheckboxComponent";
import Tippy from "@tippy.js/react";
import DefaultErrorHandler from "../common/ErrorHandler";
import i18next from "i18next";
import RepeatService from "../../service/RepeatService";

let t = i18next.t.bind(i18next);

export default class HabitTrackerSettingsComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.repeatService = RepeatService.getInstance(this.trelloService);
    this.ctx = this.trelloService.getContext();
    this.errorHandler = new DefaultErrorHandler();
    this.state = {
      lists: [], //{id, name}
      doneListIds: [],
    };
    this.originalDoneListIds = null;
    this.cancelablePromises = [];
  }


  componentDidMount() {
    window.ga('send', 'pageview', "/ht_settings_embeded");
    let cp = makeCancelable(Promise.all([
      this.repeatService.getDoneListIds(this.ctx.board),
      this.trelloService.getAllBoardLists()
    ]));
    this.cancelablePromises.push(cp);

    cp.promise.then(res => {
      let doneListIds = res[0];
      let lists = res[1];
      this.originalDoneListIds = doneListIds;
      this.setState(prev => {
        return {
          lists: lists,
          doneListIds: doneListIds
        }
      })
    })
      .catch(e => {
        this.errorHandler.onError(e)
      })
  }

  onListSelectionChange(listId, checked) {
    this.setState(prev => {
      let newDoneListIds = prev.doneListIds.concat([]); //copy
      if (checked && !prev.doneListIds.includes(listId)) {
        newDoneListIds.push(listId)
      } else {
        newDoneListIds = newDoneListIds.filter(it => {
          return it !== listId
        })
      }

      return {
        doneListIds: newDoneListIds
      }
    });

  }

  componentWillUnmount() {
    this.cancelablePromises.forEach(it => {
      it.cancel()
    })
  }

  onSave() {
    window.ga('send', 'event', 'ht', 'save_settings');
    this.repeatService.saveDoneListIds(this.ctx.board, this.state.doneListIds)
      .then(_ => {
        this.props.onClose()
      })
      .catch(e => {
        this.errorHandler.onError(e)
      })
  }

  onCancel() {
    this.props.onClose()
  }


  render() {
    let lists = this.state.lists.map(it => {
      let checked = this.state.doneListIds.includes(it.id);
      return <div key={it.id}>
        <CheckboxComponent
          text={it.name}
          checked={checked}
          onCheckedChange={(checked) => {
            this.onListSelectionChange(it.id, checked)
          }}
        />
      </div>
    });

    let selectListsText = this.props.firstStart
      ? t("tracker.settings.title_first_launch")
      : t("tracker.settings.title");

    let canSave = this.state.doneListIds.length !== 0;

    let cancelBtn = this.props.firstStart
      ? null
      : <button className="mod-danger" id="settings-cancel-btn" onClick={this.onCancel.bind(this)}>{t("cancel_btn")}</button>;

    return <div id="settings-container">
      {this.errorHandler.getSnackController()}
      <div id="settings-sheet">
        <div id="settings-content">
          <div id="settings-header-container">
            <p id="settings-title">
              {selectListsText}
            </p>
            <p id="settings-help">
              <Tippy content={
                <div className="popup-multiline-container">
                  <span className="popup-text">
                    {t("tracker.settings.help_text")}
                  </span>
                </div>
              }
                     trigger="click"
                     placement="bottom-left"
                     arrow="true"
                     theme="light-border"
                     interactive="true"
              >
                <span className="no-highlight-on-focus pointer">
                 {t("tracker.settings.help_btn")}
                </span>
              </Tippy>

            </p>
          </div>
          <div id="settings-chb-container">
            {lists}
          </div>
          <div id="settings-btn-container">
            {cancelBtn}
            <button className="mod-primary"
                    id="settings-save-btn"
                    disabled={!canSave}
                    onClick={this.onSave.bind(this)}>{t("save_btn")}
            </button>
          </div>
        </div>
      </div>
    </div>;
  }


}

HabitTrackerSettingsComponent.propTypes = {
  firstStart: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};


