/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';

import PropTypes from "prop-types";
import "./expand.scss";

import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import {Trans} from "react-i18next";
import i18next from "i18next";

let t = i18next.t.bind(i18next);

export default class LastDonePercentExpandComponent extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    let content;

    let postfixId;
    const lastDonePercent = this.props.lastDonePercent;
    let delta = this.props.donePercent - lastDonePercent;
    const lastDonePercentExist = !isNaN(lastDonePercent);

    if (isNaN(delta)) postfixId = "";
    else if (delta > 0.125) postfixId = "tracker.expand.last_done_percent_pos_postfix";
    else if (delta < -0.125) postfixId = "tracker.expand.last_done_percent_neg_postfix";
    else postfixId = "tracker.expand.last_done_percent_mid_postfix";

    let textId;
    if (lastDonePercentExist) textId = "tracker.expand.last_done_percent";
    else textId = "tracker.expand.last_done_percent_no_data";

    content =
      <div className="expand-row-container">
        <div className="expand-row-image" id="done-percent-expand-row-image">
          <img className="expand-row-image" src={this.props.icon}/>
        </div>
        <span className="expand-row-text">
          <Trans i18nKey={textId} values={{
            lastDonePercent: Math.trunc(lastDonePercent * 100),
            postfix: t(postfixId)}}>
              N% done for last
          </Trans>
        </span>
      </div>
    ;

    return content;
  }

}

LastDonePercentExpandComponent.propTypes = {
  donePercent: PropTypes.number,
  lastDonePercent: PropTypes.number,
  icon: PropTypes.string
};
