/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import TrelloService from "../../service/TrelloService";
import NeedPurchaseComponent from "../enter/NeedPurchaseComponent";
import RepeatService from "../../service/RepeatService";
import BoardStatusService from "../../service/BoardStatusService";
import AuthComponent from "../enter/AuthComponent";
import HabitTrackerSettingsComponent from "./HabitTrackerSettingsComponent";
import HabitTrackerComponent from "./HabitTrackerComponent";
import "./habitTracker.scss"
import Loader from "../common/Loader";
import DefaultErrorHandler from "../common/ErrorHandler";
import PlaceholderComponent from "../common/PlaceholderComponent";

export default class HabitTrackerContainerComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.repeatService = RepeatService.getInstance(this.trelloService);
    this.boardStatusService = new BoardStatusService(this.trelloService, this.repeatService);
    this.errorHandler = new DefaultErrorHandler();
    this.ctx = this.trelloService.getContext();
    this.state = {
      boardConfigured: false,
      needPurchase: true,
      habitTrackerConfigured: null,
      settingsMode: false,
      loading: true,
      error: false
    }
  }

  componentDidMount() {
    this.applyBoardStatus(this.boardStatusService.getBoardStatusLocal());

  }

  applyBoardStatus(boardStatusPromise) {
    this.setLoading(true);
    return boardStatusPromise
      .then(status => {
        this.setState(prev => {
          return {
            boardConfigured: status.configured,
            needPurchase: !status.trial && !status.licenseAlive && status.version === 1//|| true
          }
        });
        return status;
      })
      .then(status => {
        if(status.configured) {
          return this.loadSettings();
        }
      })
      .then(_ => {
        this.setError(false);
        this.setLoading(false);
      })
      .catch(e => {
        this.setLoading(false);
        this.setError(true);
        this.errorHandler.onError(e)
      })

  }

  loadSettings() {
    return this.repeatService.isDoneListIdsConfigured(this.ctx.board)
      .then(configured => {
        this.setState(prev => {
          return {
            habitTrackerConfigured: configured
          }
        })
      })
  }

  setError(error) {
    this.setState(prev => {
      return {
        error: error
      }
    })
  }

  onAuthorized() {
    this.applyBoardStatus(this.boardStatusService.getBoardStatusRemote())
  }

  onCloseSettings() {
    this.loadSettings();
    this.setState(prev => {
      return {
        settingsMode: false
      }
    })
  }

  openSettings() {
    this.setState(prev => {
      return {
        settingsMode: true
      }
    })
  }

  setLoading(loading) {
    this.setState(prev => {
      return {
        loading: loading
      }
    })
  }

  render() {
    let content;
    let dataNotReady = this.state.loading; //todo_rfc плохая логика определения готовности данных, смешены 2 подхода (отдельный флаг и состояние данных), нужно использовать 1
    let boardConfigured = this.state.boardConfigured;
    let needPurchase = this.state.needPurchase;
    let needShowSettings = !this.state.habitTrackerConfigured || this.state.settingsMode;

    if (dataNotReady) {
      content = null
    } else if (this.state.error) {
      content = <div id="placeholder-container"> <PlaceholderComponent error={true}/> </div>
    } else if (boardConfigured && !needPurchase && !needShowSettings) {
      content = <HabitTrackerComponent openSettings={this.openSettings.bind(this)}/>
    } else if (boardConfigured && !needPurchase) {
      content =
        <HabitTrackerSettingsComponent onClose={this.onCloseSettings.bind(this)} firstStart={!this.state.settingsMode}/>
    } else if (boardConfigured && needPurchase) {
      content = <div className="dialog-container"><NeedPurchaseComponent/></div>
    } else { //do not show auth when loading status
      content = <div className="dialog-container"><AuthComponent authSettings={false}
                                                                 onAuthorized={this.onAuthorized.bind(this)}/></div>
    }

    return <div className="habit-tracker-container">
      {this.errorHandler.getSnackController()}
      {content}
    </div>;
  }
}
