/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';

import PropTypes from "prop-types";
import "./expand.scss";
import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import DisabledParentExpandComponent from "./DisabledParentExpandComponent";
import CardCountExpandComponent from "./CardCountExpandComponent";
import DoneCountExpandComponent from "./DoneCountExpandComponent";
import DonePercentExpandComponent from "./DonePercentExpandComponent";
import LastDonePercentExpandComponent from "./LastDonePercentExpandComponent";
import SyntheticCountExpandComponent from "./SyntheticCountExpandComponent";


export default class CellExpandComponent extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    let content;
    let cell = this.props.cell;
    let synthetic = cell.syntheticCount === 0 ? null : <div>
        <SyntheticCountExpandComponent syntheticCount={cell.syntheticCount}/>
        {rowSpace()}
    </div>;

    content =
      <div className="expand-container">
        <CardCountExpandComponent cardCount={cell.cardCount}/>
        {rowSpace()}
        {synthetic}
        <DoneCountExpandComponent doneCount={cell.doneCount}/>
        {rowSpace()}
        <DonePercentExpandComponent parentMode={false} color={cell.color} donePercent={cell.donePercent}/>
      </div>
    ;

    return content;
  }

}

function rowSpace(){
  return <div className="space-btw-expand-row"/>
}

CellExpandComponent.propTypes = {
  cell: PropTypes.object.isRequired,
};
