/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import SelectComponent, {option} from "../common/SelectComponent";
import i18next from "i18next";

let t = i18next.t.bind(i18next);

export default class AggregationTypeSelectComponent extends SelectComponent {

  constructor(props) {
    super(props);
    this.state = {
      options: [
        option("day", t("tracker.aggr_type_day_item")),
        option("week", t("tracker.aggr_type_week_item")),
        option("month", t("tracker.aggr_type_month_item")),
      ],
      title: t("tracker.aggr_type_title")
    }
  }
}
