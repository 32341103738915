/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import TrelloService from "../../service/TrelloService";
import RepeatComponent from "../repeat/RepeatComponent";
import RepeatService from "../../service/RepeatService";
import {GUMROAD_PRODUCT_ID} from "../../utils/Const";
import BoardStatusService from "../../service/BoardStatusService";
import PropTypes from "prop-types";
import CronRepeatComponent from "../repeat/CronRepeatComponent";

import "./purchase.scss"
import Tippy from "@tippy.js/react";
import SnackController from "../common/snackbar";
import Loadable from "../common/Loadable";
import Loader from "../common/Loader";
import DefaultErrorHandler from "../common/ErrorHandler";
import {Trans} from "react-i18next";
import i18next from "i18next";

let t = i18next.t.bind(i18next);


export default class PurchaseComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.repeatService = RepeatService.getInstance(this.trelloService);
    this.boardStatusService = new BoardStatusService(this.trelloService, this.repeatService);
    this.errorHandler = new DefaultErrorHandler({vertical: 'top', horizontal: 'center'});
    this.ctx = this.trelloService.getContext();
    this.snack = React.createRef();
    this.state = {
      license: "",
      originalLicense: "",
      licenseErrorState: null, //empty, invalid_license, license_using, unknown
      updatingLicense: false,
      showPremiumTitle: false
    };
  }

  componentDidUpdate() {
    this.trelloService.refreshIframeSize("#purchase-container")
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://gumroad.com/js/gumroad-embed.js";
    script.async = true;
    document.body.appendChild(script);

    this.trelloService.getBoardStatus()
      .then(status => {
        this.setState(prevState => {
          return {
            license: status.license,
            originalLicense: status.license,
            originalLicenseAlive: status.licenseAlive,
            purchased: status.purchased,
            showPremiumTitle: status.version !== 1 && !status.licenseAlive
          }
        });
      })
      .catch(e => {
        this.errorHandler.onError(e)
      });

    /*document.getElementById('gumroad-container').addEventListener('DOMNodeInserted', function (event) {
      if (event.target.parentNode.id === 'gumroad-container') {
        event.target.style.height = "100%";
      }
    }, false);*/ //fix wrong height of gumroad iframe
  }

  onLicenseChange(event) {
    let newLicense = event.target.value.trim();
    this.setState(prevState => {
      return {
        license: newLicense,
      }
    });
  }

  onDelete() {
    this.setLicenseUpdating(true);
    this.repeatService.deleteLicense(this.ctx.board)
      .then(r => {
        return this.boardStatusService.getBoardStatusRemote()
      })
      .then(r => {
        window.ga('send', 'event', 'payment', 'delete');

        this.setLicenseUpdating(false);
        this.setState(prevState => {
          return {
            license: "",
            originalLicense: "",
            originalLicenseAlive: false,
            purchased: false,
          }
        });
        this.snack.current.info(t("purchase.license_deleted_msg"), null, 6000, {vertical: 'top', horizontal: 'center'})
      })
      .catch(e => {
        this.setLicenseUpdating(false);
        this.errorHandler.onError(e)
      })
  }

  setLicenseUpdating(updating) {
    this.setState(prevState => {
      return {
        updatingLicense: updating,
      }
    });
  }

  onSave() {
    if (!this.state.license) {
      this.setState(prevState => {
        return {
          licenseErrorState: "empty",
        }
      });
      return;
    }
    let action = this.state.purchased ? "try_change" : "try_save";
    window.ga('send', 'event', 'payment', action);

    this.setLicenseUpdating(true);
    this.repeatService.saveLicense(this.ctx.board, this.state.license)
      .then(r => {
        return this.boardStatusService.getBoardStatusRemote()
      })
      .then(r => {
        window.ga('send', 'event', 'payment', 'success');
        this.setLicenseUpdating(false);
        this.snack.current.success(t("purchase.license_accepted_msg"), null, 6000, {
          vertical: 'top',
          horizontal: 'center'
        });
        return new Promise(resolve => setTimeout(resolve, 3000));
      })
      .then(r => {
        this.trelloService.closeModal()
      })
      .catch(e => {
        window.ga('send', 'event', 'payment', 'fail');
        this.setLicenseUpdating(false);
        let errorState;
        if (e.response && e.response.status) {
          if (e.response.status === 400) {
            errorState = "invalid_license"
          } else if (e.response.status === 409) {
            errorState = "license_using"
          }
        }
        if (!errorState) {
          errorState = "unknown"
        }
        this.setState(prevState => {
          return {
            licenseErrorState: errorState,
          }
        });
      })
  }

  render() {
    const alreadyPurchsed = this.state.purchased;
    const purchasedLicenseInput = this.state.license === this.state.originalLicense && alreadyPurchsed;
    const licenseAlive = this.state.originalLicenseAlive;

    let changeBtn;
    if (purchasedLicenseInput) changeBtn = <button className="mod-danger license-change-btn" id="licence-delete-btn"
                                                   onClick={this.onDelete.bind(this)}><Loadable
      loading={this.state.updatingLicense}>{t("delete_btn")}</Loadable>
    </button>;
    else if (!alreadyPurchsed) changeBtn =
      <button className="license-change-btn mod-primary" id="save-btn" onClick={this.onSave.bind(this)}><Loadable
        loading={this.state.updatingLicense}>{t("save_btn")}</Loadable></button>;
    else changeBtn = <button className="mod-primary license-change-btn" id="update-btn"
                             onClick={this.onSave.bind(this)}><Loadable
        loading={this.state.updatingLicense}>{t("update_btn")}</Loadable></button>;

    let inputLabel;
    if (!alreadyPurchsed) inputLabel = <span>{t("purchase.license_label_first_launch")}</span>;
    else if (!purchasedLicenseInput) inputLabel = <span>{t("purchase.license_label")}</span>;
    else if (licenseAlive) inputLabel =
      <span>
        <Trans i18nKey="purchase.license_label_active">
          Your license <a href="https://gumroad.com/library" id="license-active-label"
                          target="_blank">active</a>
        </Trans>
      </span>;
    else inputLabel = <span>
        {t("purchase.license_label")} <Tippy content={
        <div className="popup-multiline-container">
          <span className="popup-text"> {t("purchase.license_label_expired_description")}</span>
        </div>
      }
                                             trigger="click"
                                             placement="bottom-start"
                                             arrow="true"
                                             theme="light-border">
        <span id="license-expired-label"
              className="no-highlight-on-focus">
          {t("purchase.license_label_expired_postfix")}
        </span>
        </Tippy>
      </span>
      ;


    const premiumTitle = this.state.showPremiumTitle
      ? <div id="purchase-premium-title">
        {t("purchase.premium_not_purchased_title")}
      </div>
      : null;
    const premiumSubtitle = this.state.showPremiumTitle
      ? <div id="purchase-premium-subtitle">
        {t("purchase.premium_not_purchased_subtitle")}
      </div>
      : null;

    return <div id="purchase-container">
      <div id="purchase-scroll">
        <div id="purchase-content">
          {premiumTitle}
          {premiumSubtitle}
          <div id="purchase-content-v1">
            <div id="gumroad-container">
              <div className="gumroad-product-embed" data-gumroad-product-id={GUMROAD_PRODUCT_ID}>
                <div className="gumroad-loader-container">
                  {this.getLoader()}
                </div>
              </div>
            </div>
            <span id="license-input-label" className="input-label">{inputLabel}</span>
            <input id="license-input"
                   type="text"
                   onChange={this.onLicenseChange.bind(this)}
                   value={this.state.license ? this.state.license : ""}/>
            <span id="license-error-message">{this.errorMessage()}</span>
            <Tippy content={
              <div className="popup-multiline-container">
            <span className="popup-text">
             <Trans i18nKey="purchase.help_text">
              </Trans>
            </span>
              </div>
            }
                   trigger="click"
                   placement="bottom-start"
                   arrow="true"
                   theme="light-border"
                   interactive="true"
            >
            <span className="no-highlight-on-focus" id="license-help">{t("purchase.help_btn")}
            </span>
            </Tippy>
            {changeBtn}
            <div className="secured-block">
              <div className="secured-block-content">
                <img className="secured-img"
                  src='../../public/ic-lock.svg'/>
                <span className="secured-text">
                  Secured by <a className="secured-link" href="https://customers.gumroad.com/" target="_blank" >Gumroad.com</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <SnackController ref={this.snack}/>
        {this.errorHandler.getSnackController()}
      </div>
    </div>
  }

  getLoader() {
    return <Loader
      itemStyle={{
        backgroundColor: "#96A1B5",
      }}
    />;
  }

  errorMessage() {
    switch (this.state.licenseErrorState) {
      case "empty":
        return t("purchase.error_license_empty");
      case "license_using":
        return t("purchase.error_license_using");
      case "invalid_license":
        return t("purchase.error_license_invalid");
      case "unknown":
        return <div><Trans i18nKey="purchase.error_license_unexpected"> Unexpected error, please check internet
          connection or <a
            className="error-text"
            href="https://upgradebrain.ru/contacts"
            target="_blank"
          >contact us</a>
        </Trans></div>;
      case null:
        return null;
      default:
        throw `Unsupported licenseErrorState: ${this.state.licenseErrorState}`
    }
  }
}
