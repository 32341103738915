/*
 * FeaturePage
 *
 * List all the features
 */
import React, {PureComponent} from 'react';
import TrelloService from "../../service/TrelloService";
import {Scrollbars} from 'react-custom-scrollbars';

import PropTypes from "prop-types";
import "./habitTracker.scss";
import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import AggregationTypeSelectComponent from "./AggregationTypeSelectComponent";
import {Grid, AutoSizer, CellMeasurerCache, CellMeasurer} from "react-virtualized";
import SelectComponent from "../common/SelectComponent";
import SuperRangeHabitComponent from "./SuperRangeHabitComponent";
import ParentHabitComponent from "./ParentHabitComponent";
import CellHabitComponent from "./CellHabitComponent";
import * as ReactDOM from "react-dom";
import {ResizeObserver} from 'resize-observer';
import {getCellHeight, getCellWidth, getListBottomPadding, getListRightPadding} from "./HabitResProvider";

export default class CellTableHabitComponent extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {};
    this.tempTimestamp = new Date().getMilliseconds();
    this.table = React.createRef();


  }

  componentDidMount() {
    //let tableDom = ReactDOM.findDOMNode(this.table.current);
    //let tableScrollContainerDom = tableDom.firstChild;//querySelector('.ReactVirtualized__Grid__innerScrollContainer');

    /*console.log('AAA scroll cont ' + tableScrollContainerDom);
    const ro = new ResizeObserver((entry) => console.log('resize observed! ' + entry.target.width));
    ro.observe(tableScrollContainerDom);*/
    /*tableDom.onscroll = e => {
      console.log("AAA on custom scroll");
      this.props.onScroll({scrollLeft: tableDom.scrollLeft})
    }*/
  }

  getTotalWidth() {
    return this.table.current ? this.table.current.getTotalColumnsWidth() : 0;
  }

  getTotalHeight() {
    return this.table.current ? this.table.current.getTotalRowsHeight() : 0;
  }

  onScroll(scrollTop, scrollLeft) {
    this.table.current.scrollToPosition({scrollLeft: scrollLeft, scrollTop: scrollTop})
  }

  renderCell({rowIndex, columnIndex, key, style, parent}) {

    let rightPadding = this.isPaddingCol(columnIndex);
    let bottomPadding = this.isPaddingRow(rowIndex);

    if (rightPadding || bottomPadding) {
      return <div
        className={`${rightPadding ? "list-padding-right" : ""} ${bottomPadding ? "list-padding-bottom" : ""}`} key={key}/>
    }

    const row = this.props.cells[rowIndex];
    const cell = row[columnIndex];

    if (cell.empty) {
      return null
    }
    return (
      <div style={style} key={key}>
        <CellHabitComponent
          cell={cell}
          highlighted={this.props.highlightedRow === rowIndex && this.props.highlightedCol === columnIndex
          || this.props.highlightedRow === null && this.props.highlightedCol === columnIndex   //for selection from range
          || this.props.highlightedRow === rowIndex && this.props.highlightedCol === null}  //for selection from parent
          row={rowIndex}
          col={columnIndex}
          highlightEventListener={this.props.highlightEventListener}/>
      </div>

    );
  }

  render() {

    let content;
    //console.log('AAA on CallTable Render: ' + this.props.highlightedRow + ":" + this.props.highlightedCol);
    content =
      <div className="cells-grid-container">
        <AutoSizer>
          {({width, height}) => (
            <Grid
              id="Table"
              ref={this.table}
              height={height}
              width={width}
              rowCount={this.props.cells.length + (this.cellsNotEmpty() ? 1 : 0)}
              columnCount={(this.props.cells.length > 0 ? this.props.cells[0].length : 0) + (this.cellsNotEmpty() ? 1 : 0)}
              columnWidth={({index: col}) => this.getColWidth(col)}
              rowHeight={({index: row}) => this.getRowHeight(row)}
              cellRenderer={this.renderCell.bind(this)}
              className="cells-grid no-highlight-on-focus"
              containerStyle={{
                position: 'relative',
                overflow: 'visible'
              }}
            />
          )}
        </AutoSizer>

      </div>
    ;

    return content;
  }

  getRowHeight(row) {
    return this.isPaddingRow(row)
      ? getListBottomPadding()
      : this.cellsNotEmpty() ? getCellHeight(this.props.cells[row][0]) : 0;
  }

  getColWidth(col) {
    return this.isPaddingCol(col)
      ? getListRightPadding()
      : this.cellsNotEmpty() ? getCellWidth(this.props.cells[0][col]) : 0;
  }

  cellsNotEmpty() {
    return this.props.cells.length > 0 && this.props.cells[0].length > 0;
  }

  isPaddingCol(columnIndex) {
    return this.cellsNotEmpty() && columnIndex === this.props.cells[0].length;
  }

  isPaddingRow(rowIndex) {
    return this.cellsNotEmpty() && rowIndex === this.props.cells.length;
  }
}


CellTableHabitComponent.propTypes = {
  cells: PropTypes.array.isRequired,
  highlightedRow: PropTypes.number,
  highlightedCol: PropTypes.number,
  highlightEventListener: PropTypes.func.isRequired,
};

CellTableHabitComponent.defaultProps = {
  cells: [],
};

