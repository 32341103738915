/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import TrelloService from "../../service/TrelloService";

import PropTypes from "prop-types";
import "../common.scss";
import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import Loader from "./Loader";
import DaysOfMonthSelectComponent from "../repeat/DaysOfMonthSelectComponent";
import i18next from "i18next";
import {Trans} from "react-i18next";

let t = i18next.t.bind(i18next);

// duplicate with PlaceholderHabitTrackerComponent
export default class PlaceholderComponent extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.state = {
      expand: false
    };
  }

  componentDidMount() {
  }


  render() {
    let content;
    if (this.props.loading) {
      content = <Loader
        style={{
          height: `25px`,
          width: `50px`
        }}
        itemStyle={{
          backgroundColor:"#96A1B5",
          width: `4px`,
          marginLeft: `2px`,
          marginRight: `2px`
        }}
      />
    } else if (this.props.error) {
      content = <span id="placeholder-text">{t("placeholder_error")}</span>
    } else if (this.props.empty) {
      content =
        <span id="placeholder-text">{t("placeholder_empty")}</span>
    }

    return <div id="placeholder-content">
      {content}
    </div>
  }

}

PlaceholderComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  empty: PropTypes.bool,
};

PlaceholderComponent.defaultProps = {
  loading: false,
  error: false,
  empty: false,
};

