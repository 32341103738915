/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import RepeatService from "../../service/RepeatService";
import TrelloService from "../../service/TrelloService";
import BoardStatusService from "../../service/BoardStatusService";
import SyncRepeatCardService from "../../service/SyncRepeatCardService";
import * as Sentry from '@sentry/browser';


export default class InitComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    TrelloService.initializeGlobal()
      .then(service => {
        this.trelloService = service;
        this.repeatService = RepeatService.getInstance(this.trelloService);
        this.ctx = this.trelloService.getContext();
        this.boardStatusService = new BoardStatusService(this.trelloService, this.repeatService);
        this.syncRepeatCardService = new SyncRepeatCardService(this.trelloService, this.repeatService, this.boardStatusService);

        this.syncRepeatCardService.runSynchronization();
        this.boardStatusService.refreshBoardStatusPeriodically();
        this.moveDoneListIdsToServer();
      })
  }

  componentDidMount() {
    /*const script = document.createElement("script");

    script.src = "public/client.js";
    script.async = true;

    document.body.appendChild(script);*/
  }

  render() {
    return (
      <h1>Init</h1>
    );
  }


  moveDoneListIdsToServer() {
    this.trelloService.isDoneListIdsMigrated()
      .then(migrated => {
        if(!migrated) {
          return this.trelloService.getHTSettings()
            .then(settings => {
              return this.repeatService.saveDoneListIds(this.ctx.board, settings.doneListIds)
            })
            .then(_ => {
              return this.trelloService.setDoneListIdsMigrated(true)
            })
        }
      })
  }
}
