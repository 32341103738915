/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from "prop-types";
import DaysOfMonthSelectComponent from "./DaysOfMonthSelectComponent";
import cronstrue from 'cronstrue';

import "./repeat.scss"
import Tippy from "@tippy.js/react";

import i18next from "i18next";
import {Trans} from "react-i18next";

let t = i18next.t.bind(i18next);

export default class CronRepeatComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

  }

  onChange(event) {
    if (this.props.hasOwnProperty("onCronChange")) {
      this.props.onCronChange(event.target.value)
    }
  }

  render() {
    let cronLink = "https://crontab.guru/#" + this.props.cron.replace(" ", "_");

    let cronHumanText;
    try {
      cronHumanText = cronstrue.toString(this.props.cron);  //todo_i18n
    } catch (e) {
      cronHumanText = e
    }
    let humanTextClass = "cron-detail-text " + (this.props.isValid ? "" : "cron-detail-text-error");
    return (
      <div id="cron-scheduler" className="relative-container">
        <Tippy content={
          <div className="popup-multiline-container">
            <span className="popup-text">
              <Trans i18nKey="repeat.cron.help">
                Cron is a common and powerful syntax for scheduling recurring tasks. <br/>More detailed information about cron you can find at <a
                href={cronLink}
                target="_blank">cron expression editor</a> page
          </Trans>
          </span>
          </div>
        }
               trigger="click"
               placement="bottom-start"
               arrow="true"
               theme="light-border"
               interactive="true"
        >
          <span className="no-highlight-on-focus" id="cron-help">{t("repeat.cron.need_help")}</span>
        </Tippy>
        <label className="input-label" htmlFor="cron-input">{t("repeat.cron.expression")}</label>
        <input id="cron-input"
               type="text"
               onChange={this.onChange.bind(this)}
               value={this.props.cron}/>
        <div className={humanTextClass}>{cronHumanText}</div>

      </div>
    );
  }
}


CronRepeatComponent.propTypes = {
  cron: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  onCronChange: PropTypes.func,
};

/*
let daysOfWeekText = "All";
    let monthsText = "Jan, Feb";
    let daysOfMonthText = "From 1 to 14";
    let hoursText = "All";
    let minutesText = "All";

<tableRef>
          <tbody>
          <tr>
            <td>Days of week:</td>
            <td>{daysOfWeekText}</td>
          </tr>
          <tr>
            <td>Months</td>
            <td>{monthsText}</td>
          </tr>
          <tr>
            <td>Days of month</td>
            <td>{daysOfMonthText}</td>
          </tr>
          <tr>
            <td>Hours</td>
            <td>{hoursText}</td>
          </tr>
          <tr>
            <td>Minutes</td>
            <td>{minutesText}</td>
          </tr>
          </tbody>
        </tableRef>

*/
