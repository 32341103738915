/*
 * FeaturePage
 *
 * List all the features
 */
import React, {PureComponent} from 'react';
import TrelloService from "../../service/TrelloService";
import PropTypes from "prop-types";
import "./habitTracker.scss";
import RangeHabitComponent from "./RangeHabitComponent";
import moment from "moment";


export default class SuperRangeHabitComponent extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.state = {}
  }

  componentDidMount() {
  }

  render() {
    let content;
    const superRange = this.props.superRange;
    let ranges = superRange.ranges.map((it, indx) => {
      let col = this.props.superRange.firstRangeInd + indx;
      let highlighted = col === this.props.highlightedCol;

      return <RangeHabitComponent
        range={it}
        highlighted={highlighted}
        col={col}
        key={indx}
        aggregationType={this.props.aggregationType}
        highlightEventListener={this.props.highlightEventListener}/>
    });

    content = <div id="super-range-container">
      <span id="super-range-text">{superRange.displayName}</span>
      <div id="ranges-container">{ranges}</div>
    </div>;

    return content
  }

}

SuperRangeHabitComponent
  .propTypes = {
  superRange: PropTypes.object.isRequired,
  aggregationType: PropTypes.oneOf(['day', 'week', 'month']),
  highlightedCol: PropTypes.number,
  highlightEventListener: PropTypes.func.isRequired,
};

//style={{width: `${width}px`}}
