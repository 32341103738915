/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import "../../containers/Trello/style.css";
import PropTypes from 'prop-types';
import moment from 'moment'
import DaysOfMonthSelectComponent from "../../component/repeat/DaysOfMonthSelectComponent";

import "./repeat.scss"
import i18next from "i18next";
import {fixCaretPosition} from "../common/TimeInputUtil";

let t = i18next.t.bind(i18next);

export default class MonthRepeatComponent extends React.Component {


  constructor(props) {
    super(props);
  }

  onTimeChange(e) {
    if (this.props.hasOwnProperty("onTimeChange")) {
      fixCaretPosition(e);
      let newTime = moment(e.target.value, "HH:mm").toDate();
      //console.log(newTime);
      this.props.onTimeChange(newTime)
    }
  }

  render() {

    let time = moment(this.props.time).format("HH:mm"); //todo_i18n

    return (

      <div className="flex-container">
        <div className="flex-child-two-width">
          <DaysOfMonthSelectComponent days={this.props.daysOfMonth} onDaysChange={this.props.onDaysChange}/>
        </div>
        <div className="flex-child-one-width" id='monthly-time-select'>
          <label className="input-label" htmlFor="monthly-time-input">{t("repeat.time_label")}</label>
          <input id="monthly-time-input"
                 type="time"
                 onChange={this.onTimeChange.bind(this)}
                 value={time}
                 className="repeat-time-input"/>
        </div>
      </div>
    );
  }
}


MonthRepeatComponent.propTypes = {
  daysOfMonth: PropTypes.instanceOf(Set).isRequired,
  time: PropTypes.instanceOf(Date),
  onDaysChange: PropTypes.func,
  onTimesChange: PropTypes.func
};

MonthRepeatComponent.defaultProps = {
  daysOfMonth: new Set([1]),
  time: new Date(0, 0, 0, 6, 0)
};

