export function getCellWidth(cell) {
  return cell.firstRangeInSuperRange ? 30 + 10 : 30;
}

export function getCellHeight(cell) {
  return cell.firstParentInBlock ? 40 + 33 : 40;
}

export function getParentHeight(parent) {
  return parent.firstParentInBlock ? 40 + 33 : 40;
}

export function getParentWidth() {
  return 280;
}

export function getSuperRangeWidth(superRange) {
  return 10 + 2 + 28 * superRange.ranges.length + 2 * (superRange.ranges.length - 1)
}

export function getSuperRangeHeight() {
  return 70
}

export function getListRightPadding() {
  return 16
}

export function getListBottomPadding() {
  return 16
}

export function getColor(donePercent, future) {
  if(future) return "#CCCFD6"; //future color
  let color = colors.find(it => {
    return it.start <= donePercent && it.end > donePercent
  });
  return color ? color.color : "#96A1B5"; //empty color
}

export function getHighlightedColor(donePercent, future) {
  if(future) return "#CCCFD630"; //future highlight color
  return `${getColor(donePercent)}22`
}

export function getParentGrowIcon(donePercent, lastDonePercent) { //todo_rfc duplicate with LastDonePercentExpandCompoenent
  let delta = donePercent - lastDonePercent;
  let icon;
  if(isNaN(delta)) icon = "ic-parent-grow-state-none.svg";
  else if(delta > 0.125) icon = "ic-parent-grow-state-down.svg";
  else if(delta < -0.125) icon = "ic-parent-grow-state-up.svg";
  else icon = "ic-parent-grow-state-same.svg";

  return require(`../../public/${icon}`)
}

let colors = [
  {
    start: 0,
    end: 0.25,
    color: "#eb5a47",
  },
  {
    start: 0.25,
    end: 0.5,
    color: "#f8882f",
  },
  {
    start: 0.5,
    end: 0.75,
    color: "#f6c909",
  },
  {
    start: 0.75,
    end: 1,
    color: "#b5ca34",
  },
  {
    start: 1,
    end: 1.1, //small hack
    color: "#62bc4f",
  }
];

