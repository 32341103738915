/*
 * FeaturePage
 *
 * List all the features
 */
import React, {PureComponent} from 'react';
import TrelloService from "../../service/TrelloService";

import PropTypes from "prop-types";
import "./habitTracker.scss";
import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import AggregationTypeSelectComponent from "./AggregationTypeSelectComponent";
import {Grid, AutoSizer, CellMeasurerCache, CellMeasurer} from "react-virtualized";
import SelectComponent from "../common/SelectComponent";
import SuperRangeHabitComponent from "./SuperRangeHabitComponent";
import ParentHabitComponent from "./ParentHabitComponent";
import {getInactiveOpacity, getListBottomPadding, getParentHeight} from "./HabitResProvider";


export default class HeaderHabitComponent extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.state = {};
    this.linesColRef = React.createRef();
  }

  componentDidMount() {
  }

  onScroll(scrollTop) {
    this.linesColRef.current.scrollToPosition({scrollTop: scrollTop})
  }

  renderRow({rowIndex, key, style, parent}) {
    if(this.isPaddingRow(rowIndex)) {
      return <div className="list-padding-bottom"  key={key}/>
    }

    let classes = "normal-line ";
    let parentData = this.props.parents[rowIndex];
    if (parentData.firstParentInBlock) classes += "start-block-line ";
    if (rowIndex === this.props.highlightedRow) {
      classes += "highlighted ";
    }
    //let containerStyle = parentData.enabled ? null : {opacity: getInactiveOpacity()};

    return (
      <div style={style} key={key}>
        <div className={classes}/>
      </div>
    );
  }

  render() {
    let content;
    content =
      <div className="lines-table-container">
        <AutoSizer>
          {({width, height}) => (
            <Grid
              ref={this.linesColRef}
              height={height}
              width={width}
              rowCount={this.props.parents.length + 1}
              rowHeight={({index: row}) => this.getRowHeight(row)}
              columnCount={1}
              columnWidth={width}
              cellRenderer={this.renderRow.bind(this)}
              className="lines-table-grid"
            />
          )}
        </AutoSizer>

      </div>
    ;

    return content;
  }

  getRowHeight(row) {
    return this.isPaddingRow(row)
      ? getListBottomPadding()
      : this.parentsNotEmpty() ? getParentHeight(this.props.parents[row]) : 0;
  }

  parentsNotEmpty() {
    return this.props.parents.length > 0;
  }

  isPaddingRow(rowIndex) {
    return rowIndex === this.props.parents.length;
  }

}

HeaderHabitComponent.propTypes = {
  parents: PropTypes.array.isRequired,
  highlightedRow: PropTypes.number,
};

HeaderHabitComponent.defaultProps = {
  parents: [],
};

