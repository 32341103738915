/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import TrelloService from "../../service/TrelloService";
import RepeatComponent from "../repeat/RepeatComponent";
import PropTypes from "prop-types";
import CronRepeatComponent from "../repeat/CronRepeatComponent";
import PurchaseComponent from "./PurchaseComponent";
import RepeatService from "../../service/RepeatService";
import BoardStatusService from "../../service/BoardStatusService";
import {makeCancelable} from "../../utils/PromiseUtil";
import "./auth.scss"
import Loadable from "../common/Loadable";
import DefaultErrorHandler from "../common/ErrorHandler";
import i18next from "i18next";
import {Trans} from "react-i18next";

let t = i18next.t.bind(i18next);

export default class AuthComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.repeatService = RepeatService.getInstance(this.trelloService);
    this.boardStatusService = new BoardStatusService(this.trelloService, this.repeatService);
    this.errorHandler = new DefaultErrorHandler();
    this.ctx = this.trelloService.getContext();
    this.cancelablePromises = [];
    this.state = {
      adminFullName: "",
      processing: false
    }
  }

  componentDidMount() {
    window.ga('send', 'pageview', "/auth_embeded");
    this.trelloService.refreshIframeSize(".auth-container");
    let cp = makeCancelable(this.trelloService.getAdmin());
    this.cancelablePromises.push(cp);

    cp.promise
      .then(user => {
        this.setState(prevState => {
          return {
            adminFullName: user.fullName
          }
        })
      })
      .catch(e => {
        console.error(e)
        //invalid current token token may be firs start
      })
  }

  componentWillUnmount() {
    this.cancelablePromises.forEach(it => {
      it.cancel()
    })
  }


  componentDidUpdate() {
    this.trelloService.refreshIframeSize(".auth-container");
  }

  authorize() {
    let action = this.props.authSettings ? "click_change_admin" : "click_auth";
    window.ga('send', 'event', 'auth', action);

    let results = {};
    this.setProcessing(true);
    this.trelloService.authorizeAdmin()
      .then(user => {
        results.user = user;
        return this.repeatService.saveUser(user, user.token)
      })
      .then(_ => {
        return this.repeatService.saveBoard(this.ctx.board, results.user.id)
      })
      .then(_ => {
        return this.boardStatusService.getBoardStatusRemote()
      })
      .then(_ => {
        window.ga('send', 'event', 'auth', 'success');
        this.props.onAuthorized();
        this.setProcessing(false);
      })
      .catch(e => {
        window.ga('send', 'event', 'auth', 'fail');
        this.setProcessing(false);
        this.errorHandler.onError(e);
      })
  }

  setProcessing(processing) {
    this.setState(prevState => {
      return {
        processing: processing,
      }
    });
  }

  onTermsClick() {
    window.ga('send', 'event', 'auth', 'open_terms');
    const win = window.open("https://upgradebrain.ru/terms", '_blank');
  }


  render() {
    let isSettingsMode = this.props.authSettings;
    let description = isSettingsMode
      ? <p id="auth-title">{t("auth.title")}</p>
      : <p id="auth-title">{t("auth.title_first_launch")}</p>;
    let authorizeBtnText = isSettingsMode ? t("auth.auth_btn") : t("auth.auth_btn_first_launch");
    let currentAdmin = isSettingsMode ?
      <p id="auth-subtitle">{t("auth.current_admin")}{this.state.adminFullName}</p> : "";

    let content;

    content = <div id="content" className="auth-container">
      {this.errorHandler.getSnackController()}
      {description}
      {currentAdmin}
      <button id="auth-btn" className="mod-primary" onClick={this.authorize.bind(this)}><Loadable
        loading={this.state.processing}>{authorizeBtnText}</Loadable></button>
      <div id="auth-agreement">
        <Trans i18nKey="auth.agreement">
          By clicking '{{authorizeBtnText}}', you agree with <a
          className="agreement-link"
          href="#"
          onClick={this.onTermsClick.bind(this)}
          >Terms of Service
        </a>
        </Trans>
      </div>
    </div>
    ;

    return content;
  }
}

AuthComponent.propTypes = {
  authSettings: PropTypes.bool.isRequired,
  onAuthorized: PropTypes.func
};

export class AuthPopupContainerComponent extends React.Component {

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
  }

  onAuthorized() {
    this.trelloService.closePopup()
  }

  render() {
    return <div id="content">
      <AuthComponent id="content" authSettings={false} onAuthorized={this.onAuthorized.bind(this)}/>
    </div>;
  }
}


export class AuthSettingsContainerComponent extends React.Component {

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
  }

  onAuthorized() {
    this.trelloService.closePopup()
  }

  render() {
    return <div id="content">
      <AuthComponent id="content" authSettings={true} onAuthorized={this.onAuthorized.bind(this)}/>
    </div>;
  }
}

