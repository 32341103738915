/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import '../common.scss'
import PropTypes from "prop-types";
import "./loader.scss"
export default class Loader extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
  }

  render() {
    return  <div className="spinner" style={this.props.style}>
      <div className="rect1" style={this.props.itemStyle}/>
      <div className="rect2" style={this.props.itemStyle}/>
      <div className="rect3" style={this.props.itemStyle}/>
      <div className="rect4" style={this.props.itemStyle}/>
      <div className="rect5" style={this.props.itemStyle}/>
    </div>
  }
}

Loader.propTypes = {
  style: PropTypes.any,
  itemStyle: PropTypes.any
};

