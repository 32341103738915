/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import TrelloService from "../../service/TrelloService";
import RepeatComponent from "./RepeatComponent";
import NeedPurchaseComponent from "../enter/NeedPurchaseComponent";
import RepeatService from "../../service/RepeatService";
import BoardStatusService from "../../service/BoardStatusService";
import AuthComponent from "../enter/AuthComponent";
import Loader from "../common/Loader";
import DefaultErrorHandler from "../common/ErrorHandler";
import PlaceholderComponent from "../common/PlaceholderComponent";
import "./repeat.scss"

export default class RepeatContainerComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.repeatService = RepeatService.getInstance(this.trelloService);
    this.boardStatusService = new BoardStatusService(this.trelloService, this.repeatService);
    this.errorHandler = new DefaultErrorHandler();
    this.ctx = this.trelloService.getContext();
    this.state = {
      boardConfigured: false,
      needPurchase: true,
      loading: true,
      error: false
    }
  }

  componentDidMount() {
    this.applyBoardStatus(this.boardStatusService.getBoardStatusLocal());
  }

  applyBoardStatus(boardStatusPromise) {
    this.setLoading(true);
    return boardStatusPromise
      .then(status => {
        this.setLoading(false);
        this.setError(false);
        this.setState({
          boardConfigured: status.configured,
          needPurchase: !status.trial && !status.licenseAlive && status.version === 1//|| true
        });
      })
      .catch(e => {
        this.errorHandler.onError(e);
        this.setLoading(false);
        this.setError(true);
      });

  }

  onAuthorized() {
    this.applyBoardStatus(this.boardStatusService.getBoardStatusRemote())
  }

  setLoading(loading) {
    this.setState(prev => {
      return {
        loading: loading
      }
    })
  }

  setError(error) {
    this.setState(prev => {
      return {
        error: error
      }
    })
  }

  render() {
    let content;
    let boardConfigured = this.state.boardConfigured;
    let needPurchase = this.state.needPurchase;

    if (this.state.loading) {
      content = null
    } else if (this.state.error) {
      content = <div id="placeholder-container"> <PlaceholderComponent error={true}/> </div>
    } else if (boardConfigured && !needPurchase) {
      content = <RepeatComponent/>
    } else if (boardConfigured && needPurchase) {
      content = <NeedPurchaseComponent/>
    } else {
      content = <AuthComponent authSettings={false} onAuthorized={this.onAuthorized.bind(this)}/>
    }

    return <div className="repeat-container">
      {this.errorHandler.getSnackController()}
      {content}
    </div>
    ;
  }
}
