/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import TrelloService from "../../service/TrelloService";
import RepeatService from "../../service/RepeatService";
import BoardStatusService from "../../service/BoardStatusService";
import {Link} from "react-router-dom";
import '../common.scss'
import PropTypes from "prop-types";
import SelectComponent from "./SelectComponent";
import {Trans} from "react-i18next";

/**
 * todo_rfc duplicate with repeatTrialLabel
 * used for trial (v1) and freeHabits (v2)
 */
export default class TrialLabelComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.repeatService = RepeatService.getInstance(this.trelloService);
    this.boardStatusService = new BoardStatusService(this.trelloService, this.repeatService);
    this.state = {
      trial: false,
      trialDaysLeft: 0,
      licenseAlive: true
    }
  }

  componentDidMount() {
    this.boardStatusService.getBoardStatusRemote() // Do not use error handler inside
      .then(status => {
        if(process.env.NODE_ENV !== "production") {
          console.log("Current board status: " + JSON.stringify(status));
        }
        this.setState({
          version: status.version,
          trial: status.trial, //v1
          trialDaysLeft: status.trialDaysLeft, //v1
          freeHabitsLeft: status.freeHabitsLeft, //v2
          licenseAlive: status.licenseAlive  //v2
        });
        this.trelloService.refreshIframeSize()
      });
  }


  onBuy() {
    this.trelloService.openPurchaseModal()
  }

  render() {
    if (this.state.trial && this.state.version === 1) {
      const trialDaysLeft = this.state.trialDaysLeft;
      return <div className={`trial-label-container ${this.props.containerClass}`}>
        <span className="trial-label">
           <Trans i18nKey="trial">
            Trial {{trialDaysLeft}} days. <a href="#"
                                           onClick={this.onBuy.bind(this)}
                                           className="trial-label-link">
            Buy
          </a>
          </Trans>
        </span>
      </div>
    } else if(!this.state.licenseAlive) {
      const freeHabitsLeft = this.state.freeHabitsLeft;
        return <div className={`trial-label-container ${this.props.containerClass}`}>
        <span className="trial-label">
           <Trans i18nKey="free_habits">
            {{freeHabitsLeft}} free habits left. <a href="#"
                                             onClick={this.onBuy.bind(this)}
                                             className="trial-label-link">
            Upgrade
          </a>
          </Trans>
        </span>
        </div>
      } else {
      return ""
    }
  }
}

TrialLabelComponent.propTypes = {
  containerClass: PropTypes.string,
};
