/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import TrelloService from "../../service/TrelloService";
import RepeatService from "../../service/RepeatService";
import BoardStatusService from "../../service/BoardStatusService";
import {Link} from "react-router-dom";
import '../common.scss'
import SnackController from "./snackbar";
import i18next from "i18next";
import * as Sentry from '@sentry/browser';

let t = i18next.t.bind(i18next);

export function fixCaretPosition(e) {
  let caret = e.target.selectionStart;
  if (caret === 2) {
    caret = 3
  }
  const element = e.target;
  window.requestAnimationFrame(() => {
    element.selectionStart = caret;
    element.selectionEnd = caret
  });
}
