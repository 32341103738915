/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';

import PropTypes from "prop-types";
import "./expand.scss";
import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import DisabledParentExpandComponent from "./DisabledParentExpandComponent";
import CardCountExpandComponent from "./CardCountExpandComponent";
import DoneCountExpandComponent from "./DoneCountExpandComponent";
import DonePercentExpandComponent from "./DonePercentExpandComponent";
import LastDonePercentExpandComponent from "./LastDonePercentExpandComponent";
import TrelloService from "../../../service/TrelloService";


export default class ParentExpandComponent extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.state = {}
  }

  onOpenCardClick(){
    if(this.props.parent.enabled) {
      this.trelloService.openCard(this.props.parent.cardId)
    } else {
      //if card archived, power up client cannot open card, so open it as tab
      this.trelloService.openCardViaUrl(this.props.parent.cardId)  //it open in new window
    }
  }

  render() {
    let content;
    let parent = this.props.parent;
    content =
      <div className="expand-container">
        <span className="expand-title">{parent.name}</span>
        {parent.enabled ? "" : <DisabledParentExpandComponent/> }
        {parent.enabled ? "" : rowSpace() }
        <CardCountExpandComponent cardCount={parent.cardCount}/>
        {rowSpace()}
        <DoneCountExpandComponent doneCount={parent.doneCount}/>
        {rowSpace()}
        <DonePercentExpandComponent parentMode={true} color={parent.color} donePercent={parent.last30donePercent}/>
        {rowSpace()}
        <LastDonePercentExpandComponent icon={parent.growIcon} donePercent={parent.last30donePercent} lastDonePercent={parent.last5donePercent}/>
        <div id="parent-expand-btn-container">
          <button className="mod-primary open-parent-card-btn" onClick={this.onOpenCardClick.bind(this)}>Open habit card</button>
        </div>
      </div>
    ;

    return content;
  }

}

function rowSpace(){
  return <div className="space-btw-expand-row"/>
}

ParentExpandComponent.propTypes = {
  parent: PropTypes.object.isRequired,
};


