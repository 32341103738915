/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import RepeatWeekComponent from "./WeekRepeatComponent";
import RepeatMonthComponent from "./MonthRepeatComponent";
import update from 'immutability-helper';
import SelectComponent, {option} from "../common/SelectComponent";
import i18next from "i18next";

let t = i18next.t.bind(i18next);

export default class DestinationPositionSelectComponent extends SelectComponent {

  constructor(props) {
    super(props);
    this.state = {
      options: [
        option("top", t("repeat.destination_pos_top_item")),
        option("bottom", t("repeat.destination_pos_bottom_item"))
      ],
      title: t("repeat.destination_pos_title")
    }
  }

  /*
  <div className="button-link setting form-grid-child form-grid-child-twothirds">
            <span className="label" data-i18n-id="list">Список</span>
            <span id="list-label" className="value">test</span>
<label data-i18n-id="list">Список</label>
<select id="list-selector">
    <option value="5d1668b700f53f5bade1ba94">test</option>

</select>
</div>
   */
}
