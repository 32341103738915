/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import PropTypes from "prop-types";
import '../common.scss'
import '../common/select.scss'
import i18next from "i18next";

let t = i18next.t.bind(i18next);

export default class DaysOfMonthSelectComponent extends React.Component {

  constructor(props) {
    super(props);

  }

  onSelected(event) {
    //console.log(event.target.value);
    if (this.props.hasOwnProperty("onDaysChange")) {
      this.props.onDaysChange(new Set([event.target.value])) //todo_feature multiselect, now support only single
    }
  }

  render() {

    let options = [];
    for (let i = 1; i <= 31; i++) {
      let id =  "day-of-month-id-" + i;
      options.push(<option key={id} value={i}>{i}</option>);
    }

    let selectedOptText = Array.from(this.props.days).join(", ");
    let title = t("repeat.month.select_title");
    return (
      <div className="select">
        <span className="text-beta select-label" >{title}</span>
        <span className="text-alpha select-value">{selectedOptText}</span>
        <select  className="select-input"
                 onChange={this.onSelected.bind(this)}
                value={Array.from(this.props.days)[0]}>
          {options}
        </select>
      </div>
    );
  }
}


DaysOfMonthSelectComponent.propTypes = {
  days: PropTypes.instanceOf(Set).isRequired,
  onDaysChange: PropTypes.func,
};

DaysOfMonthSelectComponent.defaultProps = {
  days: new Set([1]),
};
