import React from "react";
import axios from 'axios';

export default class SyncRepeatCardService {

  constructor(trelloService, repeatService, boardStatusService) {
    this.trelloService = trelloService;
    this.repeatService = repeatService;
    this.boardStatusService = boardStatusService;
    this.ctx = trelloService.getContext()
  }

  runSynchronization() {
    this.syncRepeatCommandEnabled();
    setInterval(() => this.syncRepeatCommandEnabled(), 30 * 60 * 1000);
  }

  syncRepeatCommandEnabled() {
    let updateEnabled = (cardId, repeatData, enabled) => {
      repeatData.enabled = enabled;
      this.trelloService.setRepeatCardData(repeatData, cardId)
    };
    let results = {};
    return this.trelloService.getRepeatCards()
      .then(cards => {
        results.cards = cards;
        return this.boardStatusService.getBoardStatusLocal();
      })
      .then(status => {
        if (status.configured) {
          results.cards.forEach(card => {
            this.repeatService.getRepeatCommand(card.id) //this logic duplicate in RepeatComponent
              .then(repeatCommand => {
                updateEnabled(card.id, card.repeatData, repeatCommand.enabled)
              })
              .catch(e => {
                if (e.response && e.response.status && e.response.status < 500) {
                  updateEnabled(card.id, card.repeatData, false) //отключаем карточки чтобы правильно отображались на ui
                }
              });
          })
        }
      });
  }
}
