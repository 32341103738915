import React from "react";
import axios from 'axios';

export default class BoardStatusService {

  constructor(trelloService, repeatService) {
    this.trelloService = trelloService;
    this.repeatService = repeatService;
    this.ctx = trelloService.getContext()
  }

  refreshBoardStatusPeriodically() {
    this.getBoardStatusRemote();
    setInterval(() => {
      this.getBoardStatusRemote()
    }, 4 * 60 * 60 * 1000)
  }

  getBoardStatusRemote() {
    return this.repeatService.getBoardStatus(this.ctx.board)
      .then(status => {
        window.ga('set', 'dimension2', status.version);
        return this.trelloService.saveBoardStatus(status);
      })
  }

  deleteBoardStatusLocal(){
    return this.trelloService.deleteBoardStatus()
  }

  getBoardStatusLocal() {
    return this.trelloService.getBoardStatus()
      .then(status => {
        if(status){
          return status
        } else {
          return this.getBoardStatusRemote()
        }
      })
  }
}
