/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import TrelloService from "../../service/TrelloService";
import RepeatService from "../../service/RepeatService";
import BoardStatusService from "../../service/BoardStatusService";
import {Link} from "react-router-dom";
import '../common.scss'
import './repeat.scss'
import TrialLabelComponent from "../common/TrialLabelComponent";
import {Trans} from "react-i18next";

/**
 * todo_rfc duplicate with TrialLabelComponent
 */
export default class RepeatTrialLabelComponent extends TrialLabelComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
  }

  render() {
    if (this.state.trial && this.state.version === 1) {
      const trialDaysLeft = this.state.trialDaysLeft;
      return <div id='repeat-trial-label'>
        <hr id="repeat-trial-hr"/>
        <span className="trial-label">
          <Trans i18nKey="trial">
          Trial {{trialDaysLeft}} days. <a href="#"
                                           onClick={this.onBuy.bind(this)}
                                           className="trial-label-link">
            Buy
          </a>
          </Trans>
      </span>

      </div>
    } else if(!this.state.licenseAlive) {
      const freeHabitsLeft = this.state.freeHabitsLeft;
      return <div id='repeat-trial-label'>
        <hr id="repeat-trial-hr"/>
        <span className="trial-label">
           <Trans i18nKey="free_habits">
            {{freeHabitsLeft}} free habits left. <a href="#"
                                                    onClick={this.onBuy.bind(this)}
                                                    className="trial-label-link">
            Upgrade
          </a>
          </Trans>
        </span>
      </div>
    } else {
      return ""
    }
  }

}
