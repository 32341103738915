/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import RepeatWeekComponent from "./WeekRepeatComponent";
import RepeatMonthComponent from "./MonthRepeatComponent";
import update from 'immutability-helper';
import SelectComponent, {option} from "../common/SelectComponent";
import i18next from "i18next";

let t = i18next.t.bind(i18next);

export default class RepeatTypeSelectComponent extends SelectComponent {

  constructor(props) {
    super(props);
    this.state = {
      options: [
        option("weekly", t("repeat.repeat_type_weekly_item")),
        option("monthly", t("repeat.repeat_type_monthly_item")),
        option("cron", t("repeat.repeat_type_cron_item")),
      ],
      title: t("repeat.repeat_type_title")
    }
  }
}
