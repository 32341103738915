/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import TrelloService from "../../service/TrelloService";
import "./purchase.scss"
import {SnackbarContent} from '@material-ui/core'

import {MySnackbarContentWrapper} from "../common/snackbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import SnackController from "../common/snackbar";
import Loader from "../common/Loader";
import Loadable from "../common/Loadable";
import {Trans} from "react-i18next";
import i18next from "i18next";

let t = i18next.t.bind(i18next);


export default class NeedPurchaseComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.state = {};
    this.snack = React.createRef();

  }

  componentDidUpdate() {
    this.trelloService.refreshIframeSize(".purchase-dialog-container")
  }

  componentDidMount() {
    window.ga('send', 'pageview', "/need_pay_embeded");
    this.trelloService.refreshIframeSize(".purchase-dialog-container")
  }

  openPurchaseWindow() {
    this.trelloService.openPurchaseModal(() => {
      //callback not work, previous iframe closed
    })
  }


  render() {
    let content;
    content = <div id="content" className="purchase-dialog-container">
      <p id="purchase-dialog-title">{t("purchase.trial_left")}</p>
      <button id="purchase-dialog-btn" className="mod-primary" onClick={this.openPurchaseWindow.bind(this)}>
        {t("purchase.pay_btn")}
      </button>
      <SnackController ref={this.snack}/>

    </div>;

    return content;
  }
}




