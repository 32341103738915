/*
 * FeaturePage
 *
 * List all the features
 */
import React, {PureComponent} from 'react';
import TrelloService from "../../service/TrelloService";

import PropTypes from "prop-types";
import "./habitTracker.scss";
import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import AggregationTypeSelectComponent from "./AggregationTypeSelectComponent";
import {Grid, AutoSizer, CellMeasurerCache, CellMeasurer} from "react-virtualized";
import SelectComponent from "../common/SelectComponent";
import SuperRangeHabitComponent from "./SuperRangeHabitComponent";
import ParentHabitComponent from "./ParentHabitComponent";
import {getCellWidth, getListBottomPadding, getParentHeight} from "./HabitResProvider";


export default class HeaderHabitComponent extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.state = {
      expand: false
    };
    this.parentsColRef = React.createRef();
  }

  componentDidMount() {
  }

  onScroll(scrollTop) {
    this.parentsColRef.current.scrollToPosition({scrollTop: scrollTop})
  }

  renderParent({ rowIndex, key, style, parent }) {
    if(this.isPaddingRow(rowIndex)) {
      return <div className="list-padding-bottom" key={key}/>
    }

    return (
        <div style={style}  key={key}>
         <ParentHabitComponent
           parent={this.props.parents[rowIndex]}
           highlighted={this.props.highlightedRow === rowIndex}
           row={rowIndex}
           highlightEventListener={this.props.highlightEventListener}/>
        </div>
    );
  }

  render() {
    let content;
    content =
      <div className="parents-grid-container">
          <AutoSizer>
            {({width, height}) => (
              <Grid
                ref={this.parentsColRef}
                height={height}
                width={width}
                rowCount={this.props.parents.length + 1}
                rowHeight={({index: row}) => this.getRowHeight(row)}
                columnCount={1}
                columnWidth={width}
                cellRenderer={this.renderParent.bind(this)}
                className="parents-grid no-highlight-on-focus"
              />
            )}
          </AutoSizer>

        </div>
    ;

    return content;
  }

  parentsNotEmpty() {
    return this.props.parents.length > 0;
  }

  getRowHeight(row) {
    return this.isPaddingRow(row)
      ? getListBottomPadding()
      : this.parentsNotEmpty() ? getParentHeight(this.props.parents[row]) : 0;
  }

  isPaddingRow(rowIndex) {
    return rowIndex === this.props.parents.length;
  }
}

HeaderHabitComponent.propTypes = {
  parents: PropTypes.array.isRequired,
  highlightedRow: PropTypes.number,
  highlightEventListener: PropTypes.func.isRequired,
};

HeaderHabitComponent.defaultProps = {
  parents: [],
};

