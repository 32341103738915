import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import {amber, green} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import {makeStyles} from '@material-ui/core/styles';
import TrelloService from "../../service/TrelloService";

import "./snack.scss"


export default class SnackController extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      message: "",
      variant: "error",
      action: null,
      durationMs: 6000,
      classes: null,
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
    }
  }

  show(message, variant, action, durationMs, anchorOrigin) { //todo_rfc change how to pass params for more flexible control, may be pass as object
    this.setState(_ => {
      return {
        visible: true,
        message: message,
        variant: variant,
        action: action,
        durationMs: durationMs,
        anchorOrigin: anchorOrigin
        //classes: classes //SnackbarContent Classes
      }
    })
  }

  success(message, action, durationMs, anchorOrigin) {
    this.show(message, "success", action, durationMs, anchorOrigin)
  }

  info(message, action, durationMs, anchorOrigin) {
    this.show(message, "info", action, durationMs, anchorOrigin)
  }

  error(message, action, durationMs, anchorOrigin) {
    this.show(message, "error", action, durationMs, anchorOrigin)
  }

  handleClose(e) {
    this.setState(_ => {
      return {visible: false}
    })
  }

  render() {
    let content;
    let duration = this.state.durationMs ? this.state.durationMs : 6000;
    content = <Snackbar
      open={this.state.visible}
      autoHideDuration={duration}
      onClose={this.handleClose.bind(this)}
      anchorOrigin={this.state.anchorOrigin}
    >
      <MySnackbarContentWrapper
        onClose={this.handleClose.bind(this)}
        variant={this.state.variant}
        message={this.state.message}
        action={this.state.action}
        //classes={this.state.classes}
      />
    </Snackbar>;

    return content;
  }
}


const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: "#DB634F",
  },
  info: {
    backgroundColor: "#f9bd0f",
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,

  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
  },
}));

export function MySnackbarContentWrapper(props) {
  const styles = useStyles1();
  const {message, onClose, variant, action, ...other} = props;



  let finalAction = action ? action : [
    <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
      <CloseIcon className={styles.icon}/>
    </IconButton>,
  ];

  let classes = null;
  if(!action) {
    classes = {
      message: "default_message",
      action: "default_action"
    }
  }

  return (
    <SnackbarContent
      className={clsx(styles[variant])}
      classes={classes}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={styles.message}>
          {message}
        </span>
      }
      action={finalAction}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  //classes: PropTypes.object
  action: PropTypes.object,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

/*

message={
        <span id="client-snackbar" className={classes.message}>
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon}/>
        </IconButton>,
      ]}
*/

/*
        <div id="message-wrap">
        <span id="message-left" className={classes.message}>
          {message}
        </span>
          <div id="message-right-action">
              <CloseIcon className={classes.icon}/>
          </div>
        </div>
 */
