/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import TrelloService from "../../service/TrelloService";
import Tippy from '@tippy.js/react'

import RepeatService from "../../service/RepeatService";
import moment from "moment";
import {chunk} from "../../utils/ArrayUtil";
import RangeHabitComponent from "./RangeHabitComponent";
import SuperRangeHabitComponent from "./SuperRangeHabitComponent";
import ParentHabitComponent from "./ParentHabitComponent";
import CellHabitComponent from "./CellHabitComponent";
import "./habitTracker.scss";
import update from 'immutability-helper';
import AggregationTypeSelectComponent from "./AggregationTypeSelectComponent";
import PropTypes from "prop-types";
import HabitTrackerSettingsComponent from "./HabitTrackerSettingsComponent";
import TrialLabelComponent from "../common/TrialLabelComponent";
import HeaderHabitComponent from "./HeaderHabitComponent";
import ParentsColHabitComponent from "./ParentsColHabitComponent";
import CellTableHabitComponent from "./CellTableHabitComponent";
import {ScrollSync} from "react-virtualized";
import * as ReactDOM from "react-dom";
import LinesTableHabitComponent from "./LinesTableHabitComponent";
import {getColor, getHighlightedColor, getParentGrowIcon} from "./HabitResProvider";
import {Scrollbars} from "react-custom-scrollbars";
import PlaceholderHabitTrackerComponent from "./PlaceholderHabitTrackerComponent";
import DefaultErrorHandler from "../common/ErrorHandler";

import i18next from "i18next";

let t = i18next.t.bind(i18next);


export default class HabitTrackerComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.repeatService = RepeatService.getInstance(this.trelloService);
    this.errorHandler = new DefaultErrorHandler();
    this.ctx = this.trelloService.getContext();
    this.state = {
      loading: true,
      error: false,
      habits: [],
      settings: {
        doneListIds: [],//["5d5d1a884649333c3ee62a46"],
        aggregationType: "day" //week, month
      },
      cells: [
        [
          /*{
            cards: [], //{id, name, date, parentId}
            date: new Date(),
            color: 1,
            highlighted: false
          }*/
        ]
      ],
      parents: [], //{parent: {cardId, name, enabled} //column
      superRanges: [], // Date  //row
      highlighted: {
        row: null,
        col: null
      }
    };
    //requests cache
    this.clonesData = null;
    this.parentCards = null;

    //calculate cache
    this.rangesCache = new Map(); // key - aggregationType
    this.superRangesCache = new Map();  // key - aggregationType
    this.cellsCache = new Map();  // key - aggregationType

    //main data
    this.clonesByParent = new Map(); //mutable  //parentId: List<Clone> //todo_rfc maybe need use more optimized data structure as hash table
    this.parents = []; //mutable
    this.minDate = moment();
    this.maxDate = moment(); //always today


    this.headerRef = React.createRef();
    this.tableRef = React.createRef();
    this.parentsRef = React.createRef();
    this.scrollBodyRef = React.createRef();
    this.scrollStubRef = React.createRef();
    this.linesTableRef = React.createRef();

    //tmp
    this.scrollLeft = 0;
    this.scrollTop = 0;
  }

  componentDidMount() {
    this.prepareData();
  }

  prepareData() {
    this.setLoading(true);
    this.setError(false);
    let results = {};
    this.getSettings()
      .then(settings => {
        results.settings = settings;
        return this.getClonesData();
      })
      .then(clonesData => {
        return this.prepareClones(clonesData)
      })
      .then(_ => {
        return this.getParentCards() //gets parent
      })
      .then(parentCards => {
        this.prepareParents(parentCards, this.clonesData);
        this.updateParentsUI();
      })
      .then(_ => {
        const aggregationType = results.settings.aggregationType;
        let superRanges;
        let cells;
        if (this.cellsCache.has(aggregationType) && this.superRangesCache.has(aggregationType)) {
          cells = this.cellsCache.get(aggregationType);
          superRanges = this.superRangesCache.get(aggregationType);
        } else {
          superRanges = this.calculateRanges(aggregationType, this.minDate, this.maxDate);
          cells = this.calculateCells(this.clonesByParent, this.parents, superRanges, aggregationType);
          superRanges = this.fillRangesStatus(superRanges, cells);
          this.superRangesCache.set(aggregationType, superRanges);
          this.cellsCache.set(aggregationType, cells);
        }

        this.setState(prevState => {
          return {
            superRanges: superRanges,
            cells: cells
          }
        });

        this.setLoading(false);

      })
      .catch(e => {
        this.setLoading(false);
        this.setError(true);
        this.errorHandler.onError(e)
      })
  }

  setLoading(loading) {
    this.setState(prev => {
      return {
        loading: loading
      }
    })
  }

  setError(error) {
    this.setState(prev => {
      return {
        error: error
      }
    })
  }

  getSettings() {
    return Promise.all([
      this.repeatService.getDoneListIds(this.ctx.board),
      this.trelloService.getHTSettings()
    ]).then(res => {
      let settings = {
        doneListIds: res[0], //old field, now this field stored on server, this not deleted for migration
        aggregationType: res[1].aggregationType
      };
      this.setState(prev => {
        return {
          settings: settings
        }
      });
      return settings;
    })
      .catch(e => {
        this.errorHandler.onError(e)
      });
  }

  prepareClones(clonesData) {
    clonesData.forEach(it => {
      let clones =  it.cardClones
        .map(clone => {
          const date = moment(clone.date);
          if (this.minDate && this.minDate.valueOf() > date.valueOf()) {
            this.minDate = date;
          }
          let mutableClone = {
            cardId: clone.cardId,
            parentCardId: clone.parentCardId,
            date: date,
            enabled: clone.enabled,
            done: clone.done,
            synthetic: clone.synthetic
          };
          return mutableClone;
        });
      this.clonesByParent.set(it.parentCardId, clones)
    });
    return null;
  }

  prepareParents(parentCards, clonesData) {
    let active = [];
    let inactive = [];
    parentCards.map(trelloCard => {
      let parentRow = clonesData.find(it => it.parentCardId === trelloCard.id);
      if (!parentRow) return null;
      const realCardClones = parentRow.cardClones.filter(it => !it.synthetic);
      let last30clones = realCardClones.slice(0, 30);
      let last5Clones = realCardClones.slice(0, 5);
      let last30donePercent = last30clones.reduce((n, it) => n + (it.done), 0) / last30clones.length;
      let last5donePercent = parentRow.enabled
        ? last5Clones.reduce((n, it) => n + (it.done), 0) / last5Clones.length
        : Number.NaN;
      let mutableParent = {
        cardId: trelloCard.id,
        name: trelloCard.name,
        enabled: parentRow.enabled,
        cardCount: realCardClones.length,
        doneCount: realCardClones.reduce((n, it) => n + (it.done), 0),
        last30donePercent: last30donePercent,
        last5donePercent: last5donePercent,
        color: getColor(last30donePercent),
        highlightedColor: getHighlightedColor(last30donePercent),
        growIcon: getParentGrowIcon(last30donePercent, last5donePercent)
      };
      return mutableParent
    })
      .filter(it => it !== null)
      .sort((a, b) => a.name > b.name ? 1 : -1).forEach(parent => {
      if (parent.enabled) {
        parent.firstParentInBlock = active.length === 0;
        parent.blockName = t("tracker.active_parents_block");
        active.push(parent)
      } else {
        parent.firstParentInBlock = inactive.length === 0;
        parent.blockName = t("tracker.old_parents_block");
        inactive.push(parent)
      }
    });
    let parents = active.concat(inactive);
    this.parents = parents
  }

  updateParentsUI() {
    this.setState(prev => {
      return {
        parents: this.parents
      }
    });
  }

  calculateRanges(aggregationType, min, max) {
    let newMax = max; //учет будущих повторений
    if (aggregationType === "day") {
      newMax = moment(max).add(7, 'days');
    }
    let ranges = this.getRanges(min, newMax, aggregationType);
    let superRanges = this.getSuperRanges(ranges, aggregationType);
    return superRanges
  }

  fillRangesStatus(superRanges, cellsTable) {
    superRanges.forEach((superRange) => {
      superRange.ranges.forEach((range, index) => {
        let rangeCells = cellsTable.map(row => {
          return row[superRange.firstRangeInd + index]
        })
          .filter(it => !(it.empty));
        range.cardCount = rangeCells.reduce((n, it) => n + (it.cards.filter(it => !it.synthetic).length), 0);
        range.doneCount = rangeCells.reduce((n, it) => n + (it.doneCount), 0);
        range.donePercent = range.doneCount / range.cardCount;
        range.syntheticCount =  rangeCells.reduce((n, it) => n + (it.syntheticCount), 0);
        range.color = getColor(range.donePercent, range.future);
        range.highlightedColor = getHighlightedColor(range.donePercent, range.future)
      })
    });
    return superRanges;
  }


  getRanges(min, max, aggregationType) {
    //console.log(`BBB min: ${min} ; max: ${max}`);
    let now = moment();
    let res = [];
    let maxMoment = moment(max);
    let minDelta; //sec
    switch (aggregationType) {
      case "day":
        maxMoment = maxMoment.add(1, 'days').startOf("day");
        minDelta = 2 * 30 * 24 * 60 * 60;
        break;
      case "week":
        maxMoment = maxMoment.isoWeekday(1 + 7).startOf("day");
        minDelta = 365 * 24 * 60 * 60;
        break;
      case "month":
        maxMoment = maxMoment.endOf('month').add(1, 'days').startOf("day");
        minDelta = 4 * 365 * 24 * 60 * 60;
        break;
      default:
        throw "unsupported aggregationType: " + aggregationType
    }


    let minMoment = moment(min);
    let tmp = maxMoment;
    while (tmp >= minMoment || (maxMoment.unix() - tmp.unix()) < minDelta) {
      let currentEnd = tmp;
      let prevEnd;
      switch (aggregationType) {
        case "day":
          prevEnd = currentEnd.clone().subtract(1, 'day');
          break;
        case "week":
          prevEnd = currentEnd.clone().subtract(1, 'week');
          break;
        case "month":
          prevEnd = currentEnd.clone().subtract(1, 'month');
          break;
        default:
          throw "unsupported aggregationType: " + aggregationType
      }
      let start = prevEnd;
      let end = currentEnd;
      res.push({
        start: start,
        end: end,
        aggregationType: aggregationType,
        now: now.isSameOrAfter(start) && now.isBefore(end),
        future: now.isBefore(start)
      });
      tmp = prevEnd
    }

    return res;
  }

  getSuperRanges(ranges, aggregationType) {
    let result = [];
    let lastRange = null;
    let superRange = {ranges: [], firstRangeInd: 0};
    ranges.forEach((it, ind) => {
      if (lastRange &&
        ((aggregationType === "month" && it.start.year() !== lastRange.start.year())
          || (aggregationType !== "month" && it.start.month() !== lastRange.start.month()))) {
        this.prepareSuperRangeDisplayName(superRange, aggregationType);
        result.push(superRange);
        superRange = {ranges: [], firstRangeInd: ind}
      }
      superRange.ranges.push(it);
      lastRange = it
    });
    this.prepareSuperRangeDisplayName(superRange, aggregationType);
    result.push(superRange);
    return result;
  }

  prepareSuperRangeDisplayName(superRange, aggregationType) {
    const start = superRange.ranges[0].start;
    const now = moment();
    let text;
    if (aggregationType === "month") {
      if (superRange.ranges.length < 2) {
        text = start.format("YY");
      } else {
        text = start.format("YYYY");
      }
    } else {
      if (superRange.ranges.length < 3) {
        text = start.format("MMM");
      } else if (superRange.ranges.length < 4 || now.year() === start.year()) {
        text = start.format("MMMM");
      } else {
        text = start.format("MMMM 'YY")
      }
    }
    superRange.displayName = text;
  }

  calculateCells(clonesByParent, parents, superRanges, aggregationType) {
    let cells = parents.map(parent => {
      let currentCloneIndx = 0;
      let clonesForParent = clonesByParent.get(parent.cardId);
      return superRanges.map(superRange => {
        return superRange.ranges
          .map((range, ind) => {
            let cardsForRange = [];
            while (clonesForParent.length > 0
              && currentCloneIndx < clonesForParent.length
              && clonesForParent[currentCloneIndx].date.isAfter(range.end)) {
              currentCloneIndx++ //skip synthetic without range
            }
            while (clonesForParent.length > 0
            && currentCloneIndx < clonesForParent.length
            && this.dateInRange(clonesForParent[currentCloneIndx].date, range)) {
              const cardClone = clonesForParent[currentCloneIndx];
              if(!(aggregationType !== "day" && cardClone.synthetic)) { //add synthetic only for aggregation by day
                cardsForRange.push(cardClone);
              }
              currentCloneIndx++
            }
            if (cardsForRange.length === 0) {
              return {
                empty: true,
                firstParentInBlock: parent.firstParentInBlock,
                firstRangeInSuperRange: ind === 0,
              }
            }
            let doneCount = cardsForRange.filter(it => it.done).length;
            let syntheticCount = cardsForRange.filter(it => it.synthetic).length;
            let synthetic = syntheticCount === cardsForRange.length;
            const realCardCount = cardsForRange.length - syntheticCount;
            const donePercent = doneCount / realCardCount;
            return {
              firstParentInBlock: parent.firstParentInBlock,
              firstRangeInSuperRange: ind === 0,
              enabled: parent.enabled,
              cards: cardsForRange,
              cardCount: realCardCount,
              doneCount: doneCount,
              donePercent: donePercent,
              color: getColor(donePercent, synthetic),
              highlightedColor: getHighlightedColor(donePercent, synthetic),
              syntheticCount: syntheticCount,
              synthetic: synthetic
            }
          })

      }).flat()
    });
    return cells;
  }

  onHighlightChange(row, col) {
    this.setState(prevState => {
      return {
        highlighted: {
          row: row,
          col: col
        }
      }
    })
  }

  onAggregationTypeChange(newValue) {
    window.ga('send', 'event', 'ht', 'change_aggr', newValue);
    let newSettings;
    this.setState(prev => {
        newSettings = update(prev.settings, {aggregationType: {$set: newValue}});
        return {
          settings: newSettings,
          superRanges: [],
          cells: []
        }
      },
      () => {
        this.trelloService.saveHTSettings(newSettings)
          .then(_ => {
            this.prepareData()
          })
          .catch(e => {
            this.errorHandler.onError(e)
          });
      })
  }

  openSettings() {
    this.props.openSettings()
  }


  scroll(e) {
    //console.log("AAA GGG onScroll " + JSON.stringify(e));

    this.tableRef.current.onScroll(this.scrollBodyRef.current.scrollTop, this.scrollBodyRef.current.scrollLeft);
    this.headerRef.current.onScroll(this.scrollBodyRef.current.scrollLeft);
    this.parentsRef.current.onScroll(this.scrollBodyRef.current.scrollTop);
    this.linesTableRef.current.onScroll(this.scrollBodyRef.current.scrollTop);

    let newWidth = this.tableRef.current.getTotalWidth() + 281; //todo_rfc dimension provider
    let newHeight = this.tableRef.current.getTotalHeight() + 70;

    if (this.tableRef.current.getTotalWidth() !== 0 && this.scrollStubRef.current.width !== newWidth) {
      this.scrollStubRef.current.style.width = newWidth + "px";
    }
    if (this.tableRef.current.getTotalHeight() !== 0 && this.scrollStubRef.current.height !== newHeight) {
      this.scrollStubRef.current.style.height = newHeight + "px";
    }
  }

  clearScroll() {
    this.scrollStubRef.current.scrollLeft = 0;
    this.scrollStubRef.current.scrollTop = 0;

    this.tableRef.current.onScroll(0, 0);
    this.headerRef.current.onScroll(0);
    this.parentsRef.current.onScroll(0);
    this.linesTableRef.current.onScroll(0);
    //почему-то не сбрасывает позицию на 0, но хотябы убирает баг с прыгающим скролом после перглючения типа аггрегации
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.settings.aggregationType !== this.state.settings.aggregationType) {
      this.clearScroll()
    }
  }

  render() {
    //console.log('AAA on HT render: ' + this.state.highlighted.row + ":" + this.state.highlighted.col);
    //console.log("AAA data: " + JSON.stringify(Array.from(this.clonesByParent.entries())));
    //console.log("AAA data: " + JSON.stringify(this.state.superRanges));

    return <div ref={this.scrollBodyRef}
                className="habit-tracker"
                onScroll={this.scroll.bind(this)}>

      <div className="habit-tracker-window">

        {this.errorHandler.getSnackController()}

        <LinesTableHabitComponent
          parents={this.state.parents}
          highlightedRow={this.state.highlighted.row}
          ref={this.linesTableRef}
        />

        <div id="habit-tracker-placeholder-container">
          <PlaceholderHabitTrackerComponent loading={this.state.loading}
                                            error={this.state.error}
                                            empty={this.state.parents.length === 0}/>
        </div>

        <CellTableHabitComponent
          cells={this.state.cells}
          highlightedRow={this.state.highlighted.row}
          highlightedCol={this.state.highlighted.col}
          highlightEventListener={this.onHighlightChange.bind(this)}
          ref={this.tableRef}
        />

        <ParentsColHabitComponent
          parents={this.state.parents}
          highlightedRow={this.state.highlighted.row}
          highlightEventListener={this.onHighlightChange.bind(this)}
          ref={this.parentsRef}
        />
        <div className="vertical-divider parents-divider"/>


        <div id="header-container">
          <HeaderHabitComponent
            aggregationType={this.state.settings.aggregationType}
            onAggregationTypeChange={this.onAggregationTypeChange.bind(this)}
            openSettingsListener={this.openSettings.bind(this)}
            superRanges={this.state.superRanges}
            highlightedCol={this.state.highlighted.col}
            highlightEventListener={this.onHighlightChange.bind(this)}
            ref={this.headerRef}
          />

        </div>

        <TrialLabelComponent containerClass="habit-trial-container"/>

      </div>
      <div className="scroll-stub" ref={this.scrollStubRef} style={{width: 10000, height: 10000}}/>
    </div>;
  }


  getClonesData() {
    return this.clonesData
      ? Promise.resolve(this.clonesData)
      : this.repeatService.getCardClones(this.ctx.board)
        .then(clonesData => {
          this.clonesData = clonesData;
          return clonesData
        });
  }

  getParentCards() {
    return this.parentCards
      ? Promise.resolve(this.parentCards)
      : this.trelloService.getCardsByIds(this.clonesData.map(clone => clone.parentCardId))
        .then(parentCards => {
          this.parentCards = parentCards;
          return parentCards
        });
  }


  compareDate(dateLeft, dateRight) {
    if (dateLeft > dateRight) {
      return 1;
    }
    if (dateLeft < dateRight) {
      return -1;
    }
    return 0;
  }

  dateInRange(date, range) {
    let result = date.isSameOrAfter(range.start) && date.isBefore(range.end);
    //console.log(`date ${date} in ${range.start} - ${range.end} : ${result}`)
    return result
  }
}

HabitTrackerComponent.propTypes = {
  openSettings: PropTypes.func.isRequired,
};

/**
 var promise = funcs[0](input);
 for (var i = 1; i < funcs.length; i++)
 promise = promise.then(funcs[i]);
 */

