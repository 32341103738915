/*
 * FeaturePage
 *
 * List all the features
 */
import React, {PureComponent} from 'react';
import TrelloService from "../../service/TrelloService";

import PropTypes from "prop-types";
import "./habitTracker.scss";
import Tippy from "@tippy.js/react";
import {getInactiveOpacity} from "./HabitResProvider";
import ParentExpandComponent from "./expand/ParentExpandComponent";
import RangeExpandComponent from "./expand/RangeExpandComponent";

const colorLabelStyleCache = {};


export default class ParentHabitComponent extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.state = {}
  }

  componentDidMount() {
  }

  onMouseOut(e) {
    if (this.props.highlightEventListener) {
      this.props.highlightEventListener(null, null)
    }
  }

  onMouseOver(e) {
    if (this.props.highlightEventListener) {
      this.props.highlightEventListener(this.props.row, null)
    }
  }

  expand() {
    window.ga('send', 'event', 'ht', 'click_parent');
    this.setState(prev => {
      return {expand: true}
    });
  }

  onCollapse() {
    this.setState(prev => {
      return {expand: false}
    });
  }

  render() {
    let content;
    let containerClasses = this.props.highlighted ? "parent-highlighted" : "";
    const parent = this.props.parent;
    let text = parent ? parent.name : "";

    let colorLabelStyleKey = parent.color;
    let colorLabelStyle = colorLabelStyleCache[colorLabelStyleKey];
    if (!colorLabelStyle) {
      colorLabelStyle = {"backgroundColor": parent.color};
      colorLabelStyleCache[colorLabelStyleKey] = colorLabelStyle;
    }

    let blockTitle = parent.firstParentInBlock ? <span id="parent-block-title">{parent.blockName}</span> : "";
    content =
      <div id="parent-container">
        {blockTitle}
        <div id="parent"
             className={`no-highlight-on-focus ${containerClasses}`}
             onMouseOver={this.onMouseOver.bind(this)}
             onMouseOut={this.onMouseOut.bind(this)}
             onClick={this.expand.bind(this)}>
          <div id="parent-color-label" style={colorLabelStyle}>
            <img
              src={parent.growIcon}/>
          </div>
          <div id="parent-text"> {text} </div>
        </div>
      </div>;

    return this.state.expand ?
      <Tippy content={
        <ParentExpandComponent parent={parent}/>
      }
             placement="right-start"
             arrow="true"
             theme="light-border"
             onHidden={this.onCollapse.bind(this)}
             visible={true}
             interactive="true"
      >
        {content}
      </Tippy>
      : content;
  }


}

ParentHabitComponent.propTypes = {
  parent: PropTypes.object,
  highlighted: PropTypes.bool.isRequired,
  row: PropTypes.number.isRequired,
  highlightEventListener: PropTypes.func,
};

