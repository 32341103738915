/*
 * FeaturePage
 *
 * List all the features
 */
import React, {PureComponent} from 'react';
import PropTypes from "prop-types";
import "./habitTracker.scss";
import TrelloService from "../../service/TrelloService";
import CellExpandComponent from "./expand/CellExpandComponent";
import Tippy from "@tippy.js/react";
import RangeExpandComponent from "./expand/RangeExpandComponent";

const colorLabelStyleCache = {};
const containerStyleCache = {};



export default class RangeHabitComponent extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.state = {
      expand: false
    }
  }

  componentDidMount() {
  }

  onMouseOut(e) {
    this.props.highlightEventListener(null, null)
  }

  onMouseOver(e) {
    this.props.highlightEventListener(null, this.props.col)
  }

  expand() {
    window.ga('send', 'event', 'ht', 'click_date');
    this.setState(prev => {
      return {expand: true}
    });
  }

  onCollapse(){
    this.setState(prev => {
      return {expand: false}
    });
  }

  render() {
    let content;
    const range = this.props.range;
    const start = range.start;
    const dayOff = range.aggregationType === 'day' && (start.isoWeekday() === 6 || start.isoWeekday() === 7);
    let textStyles = dayOff ? "day-off" : "";

    let highlightNow = (range.now && range.aggregationType === 'day');
    let containerStyleKey = `${this.props.highlighted}-${range.highlightedColor}-${highlightNow}`;
    let containerStyle = containerStyleCache[containerStyleKey];
    if (!containerStyle) {
      containerStyle = {backgroundColor: (this.props.highlighted || highlightNow) ? range.highlightedColor : null};
      containerStyleCache[containerStyleKey] = containerStyle;
    }

    let colorLabelStyleKey = range.color;
    let colorLabelStyle = colorLabelStyleCache[colorLabelStyleKey];
    if (!colorLabelStyle) {
      colorLabelStyle = {"backgroundColor": range.color};
      colorLabelStyleCache[colorLabelStyleKey] = colorLabelStyle;
    }

    let text = range.aggregationType === "month" ? (start.month() + 1) : start.date();

    content =
        <div id="range-container" key={this.props.col}
             style={containerStyle}
             onMouseOver={this.onMouseOver.bind(this)}
             onMouseOut={this.onMouseOut.bind(this)}
             onClick={this.expand.bind(this)}>
          <span id="range-text" className={textStyles}>{text}</span>
          <div id="range-color-label" style={colorLabelStyle}/>
        </div>;

    return this.state.expand ?
      <Tippy content={
        <RangeExpandComponent range={range}/>
      }
             placement="bottom-start"
             arrow="true"
             theme="light-border"
             onHidden={this.onCollapse.bind(this)}
             visible={true}
      >
        {content}
      </Tippy>
      : content;
  }

}

RangeHabitComponent.propTypes = {
  range: PropTypes.object.isRequired,
  highlighted: PropTypes.bool.isRequired,
  col: PropTypes.number.isRequired,
  highlightEventListener: PropTypes.func.isRequired,
};

