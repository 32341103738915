import React from "react";
import axios from 'axios';
import {BASE_HT_URL, HT_SERVER_TIMEOUT} from "../utils/Const";
import moment from "moment";

export default class RepeatService {

  constructor(axiosInstance){
    this.axios = axiosInstance
  }

  getNextDates(cron) {
    let now = moment().format("YYYY-MM-DDTHH:mm:ss");
    return this.axios.get(`/repeat_command/next_date?cron=${cron}&startDate=${now}`)
      .then(res => {
        return res.data
      })
  }

  save(cardId, cron, repeatType, boardId, destinationListId, destinationPosition,
       notificationEnabled, notifyInHours, notifyInMinutes) {
    let body = {
      cardId: cardId,
      cron: cron,
      repeatType: repeatType,
      boardId: boardId,
      destinationListId: destinationListId,
      destinationPosition: destinationPosition,
      notificationEnabled: notificationEnabled,
      notifyInHours: notifyInHours,
      notifyInMinutes: notifyInMinutes
    };
    return this.axios.post(`/repeat_command`, body)
  }

  delete(cardId) {
    return this.axios.delete(`/repeat_command?cardId=${cardId}`)
  }

  getRepeatCommand(cardId) {
    return this.axios.get(`/repeat_command?cardId=${cardId}`)
      .then(resp => {
        return resp.data
      })
  }

  getBoardStatus(boardId) {
    return this.axios.get(`/board/status?id=${boardId}`)
      .then(resp => {
        return resp.data
      })
  }

  getDoneListIds(boardId) {
    return this.axios.get(`/board/doneListIds?boardId=${boardId}`)
      .then(resp => {
        return resp.data.doneListIds
      })
  }

  saveDoneListIds(boardId, doneListIds) {
    let body = {
      boardId: boardId,
      doneListIds: doneListIds
    };
    return this.axios.post(`/board/doneListIds`, body)
  }

  isDoneListIdsConfigured(boardId) {
    return this.getDoneListIds(boardId)
      .then(doneListIds => {
        return doneListIds.length > 0
      })
  }

  saveUser(user, token) {
    let body = {
      trelloToken: token
    };
    return this.axios.post(`/user`, body)
  }

  saveBoard(boardId, adminUserId) {
    let body = {
      id: boardId,
      adminId: adminUserId,
      timezoneOffsetMin: new Date().getTimezoneOffset() * (-1), //invert for normal offset https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
    };
    return this.axios.post(`/board`, body)
  }

  saveLicense(boardId, license) {
    let body = {
      boardId: boardId,
      license: license,
    };
    return this.axios.post(`/board/license`, body)
  }

  deleteLicense(boardId) {
    return this.axios.delete(`/board/license?boardId=${boardId}`)
  }

  deactivateBoard(boardId) {
    return this.axios.delete(`/board?sendEmail=true&boardId=${boardId}`)
  }

  getCardClone(cardId) {
    return this.axios.get(`/card_clone?cardId=${cardId}`)
      .then(resp => {
        return resp.data
      })
  }

  getCardClones(boardId) {
    return this.axios.get(`/card_clone/board?boardId=${boardId}`)
      .then(resp => {
        return resp.data
      })
      .then(data => {
        data.forEach(it => {
          it.cardClones.forEach(it => {
            it.date = new Date(it.date) //convert string to date //todo_rfc use moment js?
          })
        });
        return data
      })
  }

  static getInstance(trelloService) {
    const axiosInstance = axios.create({
      baseURL: BASE_HT_URL,
      timeout: HT_SERVER_TIMEOUT,
      headers: {'contentType':'application/json'}
    });

    axiosInstance.interceptors.request.use(function (config) {
      return trelloService.getToken()
        .then(token => {
          config.headers.Authorization = `Bearer ${token}`;
          return Promise.resolve(config);
        });
    }, function (error) {
      return Promise.reject(error);
    });
    return new RepeatService(axiosInstance);
  }


}
