/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import TrelloService from "../../service/TrelloService";

import PropTypes from "prop-types";
import "./habitTracker.scss";
import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import {getInactiveOpacity} from "./HabitResProvider";
import CellExpandComponent from "./expand/CellExpandComponent";
import { Textfit } from 'react-textfit';

//use save style object for avoid rerendering
const cellContainerStyleCache = {};
const cellTextStyleCache = {};

export default class CellHabitComponent extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.state = {
      expand: false
    };
  }

  componentDidMount() {
  }

  onMouseOut(e) {
    this.props.highlightEventListener(null, null)
  }

  onMouseOver(e) {
    this.props.highlightEventListener(this.props.row, this.props.col)
  }

  expand() {
    window.ga('send', 'event', 'ht', 'click_cell');
    this.setState(prev => {
      return {expand: true}
    });
  }

  onCollapse() {
    this.setState(prev => {
      return {expand: false}
    });
  }


  render() {
    let content;
    /*return <div
      ></div>;*/
    const cell = this.props.cell;


    let containerClasses = "";
    if (cell.firstParentInBlock) containerClasses += "cell-first-parent-in-block ";
    if (cell.firstRangeInSuperRange) containerClasses += "cell-first-range-in-super-range ";
    if (cell.empty) {
      return <div
        className={`cell-container cell-container-empty no-highlight-on-focus ${containerClasses}`}/>
    }
    //console.log(`AAA cell ${this.props.row}:${this.props.col}  H: ${this.props.highlighted}`);

    let color = cell.color;
    let backgroundColor = this.props.highlighted ? cell.highlightedColor : null;
    let containerStyleKey = color;
    let textStyleKey = `${color}-${backgroundColor}`;

    let containerStyle = cellContainerStyleCache[containerStyleKey];
    if (!containerStyle) {
      containerStyle = {borderColor: color};
      cellContainerStyleCache[containerStyleKey] = containerStyle;
    }

    let textStyle = cellTextStyleCache[textStyleKey];
    if(!textStyle) {
       textStyle = {color: color, backgroundColor: backgroundColor};
       cellTextStyleCache[textStyleKey] = textStyle;
    }

    let text = cell.synthetic ? cell.syntheticCount : (cell.cards.length > 0 ? cell.doneCount : "");
    content =
      <div
        className={`cell-container no-highlight-on-focus ${containerClasses}`}
        style={containerStyle}
        onMouseOver={this.onMouseOver.bind(this)}
        onMouseOut={this.onMouseOut.bind(this)}
        onClick={this.expand.bind(this)}>
        <Textfit mode="single"
                 className="cell-text"
                 style={textStyle}
                 max={14}>
            {text}
        </Textfit>
      </div>
    ;

    return this.state.expand
      ? <Tippy content={
        <CellExpandComponent cell={cell}/>
      }
               placement="bottom-start"
               arrow="true"
               theme="light-border"
               onHidden={this.onCollapse.bind(this)}
               visible={true}
      >
        {content}
      </Tippy>
      : content;
  }

}

CellHabitComponent
  .propTypes = {
  cell: PropTypes.object,
  highlighted: PropTypes.bool.isRequired,
  row: PropTypes.number.isRequired,
  col: PropTypes.number.isRequired,
  highlightEventListener: PropTypes.func.isRequired,
};

/*

content =
      <Tippy content={
        <div>
          <span>All cards: {cell.cards.length}</span> <br/>
          <span>Cards with done status: {cell.doneCount}</span>
        </div>
      }
             trigger="click"
             placement="bottom-start"
             arrow="true"
             theme="light-border"
      >
<div
  className={`cell-container no-highlight-on-focus ${containerStyles}`}
  style={{"borderColor": color}}
  onMouseOver={this.onMouseOver.bind(this)}
  onMouseOut={this.onMouseOut.bind(this)}>
          <span className="cell-text"
                style={{"color": color}}>
            {text}
          </span>
</div>
</Tippy>
;
 */
