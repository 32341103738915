/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';

import PropTypes from "prop-types";
import "./expand.scss";

import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import {Trans} from "react-i18next";


export default class SyntheticCountExpandComponent extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    let content;
    const syntheticCount = this.props.syntheticCount;
    content =
      <div className="expand-row-container">
        <img className="expand-row-image" src={require('../../../public/ic-future-cards.svg')}/>
        <span className="expand-row-text">
          <Trans i18nKey="tracker.expand.synthetic_count" count={syntheticCount}>
            {{syntheticCount}} cards
          </Trans>
        </span>
      </div>
    ; //todo_i18n (russian) plural cardsCount

    return content;
  }

}

SyntheticCountExpandComponent.propTypes = {
  syntheticCount: PropTypes.number,
};
