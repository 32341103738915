/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import update from 'immutability-helper';
import SelectComponent, {option} from "../common/SelectComponent";
import i18next from "i18next";
import TrelloService from "../../service/TrelloService";

let t = i18next.t.bind(i18next);

export default class DestinationListSelectComponent extends SelectComponent {

  constructor(props) { //https://developers.trello.com/reference#t-lists
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.state = {
      options: [],
      selectedOptionValue: props.selectedOptionValue ? props.selectedOptionValue : "",
      title: t("repeat.destination_list_title")
    }
  }

  componentDidMount() {
    this.trello = window.TrelloPowerUp.iframe(); //todo_rfc service
    let listsRes = null;
    this.trello.lists('all')
      .then((lists) => {
        listsRes = lists;
        let listOptions = lists.map(list => {
          return option(list.id, list.name)
        });
        let newState = update(this.state, {
          options: {$set: listOptions},
        });
        this.setState(newState);
      })
      .then(_ => {
        return this.trelloService.getLastDestinationListId()
      })
      .then(listId => {
        if (listId
          && listsRes.filter(list => list.id === listId).length !== 0
          && !this.props.selectedOptionValue) {
          this.onSelected(listId)
        } else {
          this.chooseSelectedValueIfNotSpecified()
        }
      })
      .catch(e => {
        this.onError(e);
      });
  }
}
