/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import RepeatWeekComponent from "../repeat/WeekRepeatComponent";
import RepeatMonthComponent from "../repeat/MonthRepeatComponent";
import update from 'immutability-helper';
import PropTypes from "prop-types";
import '../common.scss'
import './select.scss'
import DefaultErrorHandler from "./ErrorHandler";


export function option(value, text) {
  return {
    value: value,
    text: text
  };
}

export default class SelectComponent extends React.Component {

  constructor(props) {
    super(props);
    this.errorHandler = new DefaultErrorHandler();
    this.state = {
      options: [],
      title: "",
    }

  }

  onSelected(value) {
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange(value)
    }
  }

  chooseSelectedValueIfNotSpecified() {
    if (!this.props.selectedOptionValue && this.state.options.length > 0) {
      this.onSelected(this.state.options[0].value)
    }
  }

  componentDidMount() {
    this.chooseSelectedValueIfNotSpecified();
  }

  onError(e) {
    this.errorHandler.onError(e)
  }

  render() {
    let options = [];
    let selectedOptText;
    for (let i = 0; i < this.state.options.length; i++) {
      let opt = this.state.options[i];
      options.push(<option key={opt.value} value={opt.value}>{opt.text}</option>);
      if (opt.value === this.props.selectedOptionValue) {
        selectedOptText = opt.text
      }
    }
    return (
      <div className="select">
        {this.errorHandler.getSnackController()}
        <span className="text-beta select-label">{this.state.title}</span>
        <span className="text-alpha select-value">{selectedOptText}</span>
        <select className="select-input" onChange={e => this.onSelected(e.target.value)}
                value={this.props.selectedOptionValue}>
          {options}
        </select>
      </div>
    );
  }
}


SelectComponent.propTypes = {
  selectedOptionValue: PropTypes.string,
};

SelectComponent.defaultProps = {
  selectedOptionValue: undefined,
};
