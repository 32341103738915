/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import RepeatWeekComponent from "./WeekRepeatComponent";
import MonthRepeatComponent from "./MonthRepeatComponent";
import RepeatTypeSelectComponent from "./RepeatTypeSelectComponent";
import update from 'immutability-helper';
import DestinationPositionSelectComponent from "./DestinationPositionSelectComponent";
import DestinationListSelectComponent from "./DestinationListSelectComponent";
import CronRepeatComponent from "./CronRepeatComponent";
import RepeatService from "../../service/RepeatService";
import moment from "moment";
import TrelloService from "../../service/TrelloService";
import * as _ from 'underscore'
import "./repeat.scss"
import RepeatTrialLabelComponent from "./RepeatTrialLabelComponent";
import Loadable from "../common/Loadable";
import DefaultErrorHandler from "../common/ErrorHandler";
import i18next from "i18next";
import SnackController from "../common/snackbar";
import BoardStatusService from "../../service/BoardStatusService";
import NotifyComponent from "./NotifyRepeatComponent";

let t = i18next.t.bind(i18next);

let CronValidator = require('cron-validator');

export default class RepeatComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.licenseAlive = false;
    this.trelloService = TrelloService.getIframeInstance();
    this.repeatService = RepeatService.getInstance(this.trelloService);
    this.boardStatusService = new BoardStatusService(this.trelloService, this.repeatService);
    this.errorHandler = new DefaultErrorHandler();
    this.snack = React.createRef();
    this.state = this.defaultState();
    this.ctx = this.trelloService.getContext();
    this.trelloService.getRepeatCardData()
      .then(initialCardData => {
        this.initialCardData = initialCardData;
        if (initialCardData === null) {
          //console.log("initialdata undefined")
        } else {
          //console.log("initialdata exist! " + JSON.stringify(initialCardData));
          if (!initialCardData.notify) {
            initialCardData.notify = {
              enabled: false,
              hours: 0,
              minutes: 0,
            }
          }
          this.setState(update(initialCardData, {}));
        }
        this.checkEnabledStatus();
        this.updateCronCommandAndNextDates();
      })
      .catch(e => {
        this.errorHandler.onError(e)
      });
  }

  componentDidMount() {


    this.trelloService.getToken()
      .then(t => {
        if (process.env.NODE_ENV !== "production") {
          console.log("Trello token: " + t);
        }
      });

    this.boardStatusService.getBoardStatusLocal()
      .then(status => {
        this.licenseAlive = status.licenseAlive
      })
  }

  checkEnabledStatus() {
    let updateEnabled = enabled => {
      this.setState(prevState => {
        return {
          enabled: enabled
        }
      });
      if (this.initialCardData) {
        let cardData = this.extractCardData();
        cardData.enabled = enabled;
        this.trelloService.setRepeatCardData(cardData)
      }
    };

    this.repeatService.getRepeatCommand(this.ctx.card) //this logic duplicated in SyncRepeatCardService
      .then(repeatCommand => {
        updateEnabled(repeatCommand.enabled)
      })
      .catch(e => {
        if (e.response && e.response.status && e.response.status < 500) {
          updateEnabled(false);
        }
      });
  }

  componentDidUpdate() {
    this.trelloService.refreshIframeSize();
  }

  onDestinationPositionChange(position) {
    this.setState(prevState => {
      return {
        destination: update(prevState.destination,
          {position: {$set: position}}
        )
      }
    });
  }

  onDestinationListChange(listId) {
    this.setState(prevState => {
      return {
        destination: update(prevState.destination,
          {listId: {$set: listId}}
        )
      }
    });
  }

  onRepeatTypeChange(type) {
    this.setState(preveState => {
      return {repeatType: type}
    });
    this.updateCronCommandAndNextDates();
  }

  onWeekDaysChange(newDays) {
    this.setState(prevState => {
      return {
        weeklyUserData: update(prevState.weeklyUserData,
          {daysOfWeek: {$set: newDays}}
        )
      }
    });
    this.updateCronCommandAndNextDates();
  }

  onWeekdayTimeChange(newTime) {
    this.setState(prevState => {
      return {
        weeklyUserData: update(prevState.weeklyUserData,
          {time: {$set: newTime}}
        )
      }
    });
    this.updateCronCommandAndNextDates();
  }

  onMonthDaysChange(newDays) {
    this.setState(prevState => {
      return {
        monthlyUserData: update(prevState.monthlyUserData,
          {daysOfMonth: {$set: newDays}}
        )
      }
    });
    this.updateCronCommandAndNextDates();
  }

  onMonthdayTimeChange(newTime) {
    this.setState(prevState => {
      return {
        monthlyUserData: update(prevState.monthlyUserData,
          {time: {$set: newTime}}
        )
      }
    });
    this.updateCronCommandAndNextDates();
  }

  onCronChange(newCron) {
    this.setState(prevState => {
      return {
        cronUserData: update(prevState.cronUserData,
          {
            cron: {$set: newCron},
            valid: {$set: CronValidator.isValidCron(newCron)}
          }
        )
      }
    });
    this.updateCronCommandAndNextDates();
  }

  onNotifyHoursChange(newHours) {
    if (newHours < 0) {
      newHours = 0
    }
    this.setState(prevState => {
      return {
        notify: update(prevState.notify,
          {hours: {$set: newHours}}
        )
      }
    });
  }

  onNotifyMinutesChange(newMinutes) {
    if (newMinutes < 0) {
      newMinutes = 0
    }
    this.setState(prevState => {
      return {
        notify: update(prevState.notify,
          {minutes: {$set: newMinutes}}
        )
      }
    });
  }

  onNotifyEnabledChange(enabled) {
    /*if (enabled && !this.licenseAlive) { //код может потребоваться для АВ тестов чтобы проверить сколько покупают с этой фичей
      window.ga('send', 'event', 'repeat', 'click_notify_on_free_plan');
      this.snack.current.error(
        t("repeat.notify.notification_available_on_premium"),
        <div className="white-btn" onClick={this.onUpgradeClick.bind(this)}>
          {t("repeat.upgrade_btn")}
        </div>,
        60 * 1000)
    } else {*/
      this.setState(prevState => {
        return {
          notify: update(prevState.notify,
            {enabled: {$set: enabled}}
          )
        }
      });
    //}
  }

  onSave(e) {
    let cardData = this.extractCardData();
    window.ga('send', 'event', 'repeat', 'save', cardData.repeatType);
    cardData.enabled = true;
    this.setSaving(true);
    this.trelloService.setLastDestinationListId(cardData.destination.listId)
      .then(_ => {
        return this.repeatService.save(
          this.ctx.card,
          cardData.repeatCommand.cron,
          cardData.repeatType,
          this.ctx.board,
          cardData.destination.listId,
          cardData.destination.position,
          cardData.notify.enabled,
          cardData.notify.hours,
          cardData.notify.minutes,
        )
      })
      .then(_ => {
        return this.trelloService.setRepeatCardData(cardData)
      })
      .then(_ => {
        return this.boardStatusService.getBoardStatusRemote()
      })
      .then(_ => {
        this.setSaving(false);
        this.initialCardData = cardData;
        this.trelloService.closePopup()
      })
      .catch(e => {
        this.setSaving(false);
        if (e.response && e.response.status && e.response.status === 402) {
          window.ga('send', 'event', 'repeat', 'free_habits_expired');
          this.snack.current.error(
            t("repeat.free_habits_expired"),
            <div className="white-btn" onClick={this.onUpgradeClick.bind(this)}>
              {t("repeat.upgrade_btn")}
            </div>,
            5 * 60 * 1000)
        } else {
          this.errorHandler.onError(e)
        }
      })
  }

  onDelete() {
    if (!this.initialCardData) {
      this.trelloService.closePopup();
      return
    }

    let cardData = this.extractCardData();
    window.ga('send', 'event', 'repeat', 'delete', cardData.repeatType);
    cardData.enabled = false;
    this.setSaving(true);
    this.trelloService.setRepeatCardData(cardData)
      .then(r => {
        return this.repeatService.delete(this.ctx.card)
      })
      .then(_ => {
        return this.boardStatusService.getBoardStatusRemote()
      })
      .then(r => {
        this.initialCardData = null;
        this.setSaving(false);
        this.trelloService.closePopup()
      })
      .catch(e => {
        this.setSaving(false);
        this.errorHandler.onError(e)
      })
  }

  onUpgradeClick() {
    this.trelloService.openPurchaseModal()
  }

  updateNextDates(cron) {
    this.repeatService.getNextDates(cron)
      .then(dates => {
          this.setState(prevState => {
            return {nextDates: dates}
          })
        }
      )
      .catch(e => {
        this.setState(prevState => {
          return {nextDates: null}
        })
      });
  }

  updateCronCommandAndNextDates() {
    this.setState(prevState => {
        let cron = this.calculateCronCommand(prevState);
        this.updateNextDates(cron);
        return {
          repeatCommand: {cron: cron}
        }
      }
    )
  }

  calculateCronCommand(state) {
    let newCron;
    if (state.repeatType === "weekly") {
      let data = state.weeklyUserData;
      let minutes = data.time.getMinutes();
      let hours = data.time.getHours();
      let days = Array.from(data.daysOfWeek).join(",");
      newCron = `${minutes} ${hours} * * ${days}`
    } else if (state.repeatType === "monthly") {
      let data = state.monthlyUserData;
      let minutes = data.time.getMinutes();
      let hours = data.time.getHours();
      let days = Array.from(data.daysOfMonth).join(",");
      newCron = `${minutes} ${hours} ${days} * *`
    } else if (state.repeatType === "cron") {
      newCron = state.cronUserData.cron
    } else {
      throw "illegal repeatType: " + state.repeatType
    }
    return newCron;
  }

  setSaving(saving) {
    this.setState(prevState => {
      return {
        saving: saving,
      }
    });
  }

  render() {
    //console.log("Repeat Render");
    //console.log("Repeat Render state: " + JSON.stringify(this.state));

    let repeatsControls;
    switch (this.state.repeatType) {
      case 'weekly':
        repeatsControls = <RepeatWeekComponent daysOfWeek={this.state.weeklyUserData.daysOfWeek}
                                               time={this.state.weeklyUserData.time}
                                               onDaysChange={this.onWeekDaysChange.bind(this)}
                                               onTimeChange={this.onWeekdayTimeChange.bind(this)}/>;
        break;
      case 'monthly':
        repeatsControls = <MonthRepeatComponent daysOfMonth={this.state.monthlyUserData.daysOfMonth}
                                                time={this.state.monthlyUserData.time}
                                                onDaysChange={this.onMonthDaysChange.bind(this)}
                                                onTimeChange={this.onMonthdayTimeChange.bind(this)}/>;
        break;
      case 'cron':
        repeatsControls = <CronRepeatComponent cron={this.state.cronUserData.cron}
                                               isValid={this.state.cronUserData.valid}
                                               onCronChange={this.onCronChange.bind(this)}/>;
        break;
    }
    let nextTimeText = <span><br/><br/></span>;
    //check data
    let isDataValid = !(this.state.repeatType === "cron" && !this.state.cronUserData.valid)
      && !(this.state.repeatType === "weekly" && this.state.weeklyUserData.daysOfWeek.size === 0)
      && !(this.state.repeatType === "weekly" && isNaN(this.state.weeklyUserData.time.getTime())) //empty time
      && !(this.state.repeatType === "monthly" && isNaN(this.state.monthlyUserData.time.getTime())) //empty time
      && this.state.nextDates !== null;


    if (isDataValid && this.state.nextDates && this.state.nextDates.length > 0) {
      let dateText = moment(this.state.nextDates[0]).format("dddd, MMMM Do YYYY, h:mm a"); //todo_i18n
      nextTimeText = `${t("repeat.next_repeat")} ${dateText}`
    }

    let saveBtnEnabled = (this.isCardDataChanged() || !this.state.enabled) && isDataValid;
    let deleteBtnEnabled = this.state.enabled;

    return (
      <div id="content">
        <SnackController ref={this.snack}/>
        {this.errorHandler.getSnackController()}
        <div className="form-grid">
          <RepeatTypeSelectComponent onChange={this.onRepeatTypeChange.bind(this)}
                                     selectedOptionValue={this.state.repeatType}/>
        </div>
        <div className="repeat-vertical-space"/>

        {repeatsControls}

        <div className="repeat-vertical-space"/>
        <div className="flex-container">
          <div className="flex-child-two-width">
            <DestinationListSelectComponent
              onChange={this.onDestinationListChange.bind(this)}
              selectedOptionValue={this.state.destination.listId}/></div>
          <div id='position-select'>
            <DestinationPositionSelectComponent
              onChange={this.onDestinationPositionChange.bind(this)}
              selectedOptionValue={this.state.destination.position}/></div>
        </div>
        <div className="repeat-vertical-space"/>
        <span id="next_date_text">{nextTimeText}</span>
        <div className="repeat-vertical-space"/>
        <NotifyComponent notificationEnabled={this.state.notify.enabled}
                         onHoursChange={this.onNotifyHoursChange.bind(this)}
                         onMinutesChange={this.onNotifyMinutesChange.bind(this)}
                         onEnabledChange={this.onNotifyEnabledChange.bind(this)}
                         hours={this.state.notify.hours}
                         minutes={this.state.notify.minutes}
                         creationDate={this.state.nextDates && this.state.nextDates.length > 0 ? this.state.nextDates[0] : null}/>
        <div className="repeat-vertical-space"/>
        <div className="relative-container">
          <button className="mod-primary"
                  id="save-repeat"
                  disabled={!saveBtnEnabled}
                  onClick={this.onSave.bind(this)}>
            <Loadable
              loading={this.state.saving}>{t("save_btn")}</Loadable>
          </button>
          <button
            className="mod-danger right-absolute"
            id="delete-repeat"
            disabled={!deleteBtnEnabled}
            onClick={this.onDelete.bind(this)}>
            <Loadable
              loading={this.state.saving}>{t("delete_btn")}</Loadable>
          </button>
        </div>
        <RepeatTrialLabelComponent/>
      </div>
    );
  }

  isCardDataChanged() {
    return !_.isEqual(this.initialCardData, this.extractCardData());
  }

  extractCardData() {
    return update(this.state, {$unset: ["nextDates", "saving"]})
  }

  defaultState() {
    return {
      repeatType: 'weekly',
      enabled: false,
      repeatCommand: {
        cron: "0 6 * * 1"
      },
      destination: {
        listId: undefined,
        position: "top" /* top / bottom */
      },
      weeklyUserData: {
        daysOfWeek: new Set([1]), //mon == 1, sun = 7
        time: new Date(0, 0, 0, 6, 0)
      },
      monthlyUserData: {
        daysOfMonth: new Set([1]), //days of month
        time: new Date(0, 0, 0, 6, 0)
      },
      cronUserData: {
        cron: "0 7 1-5 */4 *",
        valid: true
      },
      notify: {
        enabled: false,
        hours: 0,
        minutes: 0,
      },
      saving: false
    }
  }
}

