/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import TrelloService from "../../service/TrelloService";
import RepeatService from "../../service/RepeatService";
import BoardStatusService from "../../service/BoardStatusService";
import {Link} from "react-router-dom";
import '../common.scss'
import SnackController from "./snackbar";
import i18next from "i18next";
import * as Sentry from '@sentry/browser';

let t = i18next.t.bind(i18next);

export default class DefaultErrorHandler {
  // eslint-disable-line react/prefer-stateless-function

  constructor(anchorOrigin) {
    this.anchorOrigin = anchorOrigin;
    this.snack = React.createRef();
  }

  getSnackController(){
    return  <SnackController ref={this.snack}/>
  }

  onError(e){
    if(e && e.isCanceled) {
      return
    }
    //if(process.env.NODE_ENV !== "production") {
    Sentry.captureException(e);
    console.error(e);
    //}
    if(this.snack.current) {
      this.snack.current.error(t("unexpected_error"), null, 6000, this.anchorOrigin)
    }
  }
}
