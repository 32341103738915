/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './power-up.scss'
import '../containers/App/style.scss';
import RepeatContainerComponent from "./repeat/RepeatContainerComponent";
import {AuthPopupContainerComponent, AuthSettingsContainerComponent} from "./enter/AuthComponent";
import HabitTrackerContainerComponent from "./habit_tracker/HabitTrackerContainerComponent";
import InitComponent from "./enter/InitComponent";
import PurchaseComponent from "./enter/PurchaseComponent";


const App = () => (

  <Switch>

      <Route path="/trello/repeat" component={RepeatContainerComponent} />
      <Route path="/trello/purchase" render={PurchaseComponentProvider} />
      <Route path="/trello/purchaseSettings" render={PurchaseSettingsProvider} />
      <Route path="/trello/authSettings" component={AuthSettingsContainerComponent} />
      <Route path="/trello/auth" component={AuthPopupContainerComponent} />
      <Route path="/trello/habitTracker" component={HabitTrackerContainerComponent} />
      <Route path="/trello/init" component={InitComponent} />
  </Switch>
);

const PurchaseComponentProvider = (props) => {
  return (
    <PurchaseComponent
      purchaseSettings={false}
    />
  );
};

const PurchaseSettingsProvider = (props) => {
  return (
    <PurchaseComponent
      purchaseSettings={true}
    />
  );
};

/*<Route exact path="/" component={HomePage} />
      <Route path="/features" component={FeaturePage} />*/

export default App;
