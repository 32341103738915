/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import TrelloService from "../../service/TrelloService";
import RepeatService from "../../service/RepeatService";
import BoardStatusService from "../../service/BoardStatusService";
import {Link} from "react-router-dom";
import '../common.scss'
import PropTypes from "prop-types";
import SelectComponent from "./SelectComponent";
import "./loader.scss"
import Loader from "./Loader";
export default class Loadable extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
  }

  render() {
    let content = this.props.loading ? <Loader/> : this.props.children;
      return content;
    /*return <div id="loadable-container">
      {content}
    </div>*/
  }
}

Loadable.propTypes = {
  loading: PropTypes.bool
};

Loadable.defaultProps = {
  loading: true,
};

