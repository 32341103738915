import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEng from "./locales/en/translation.json";
import translationRu from "./locales/ru/translation.json";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV !== "production",
    //lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: '.', // we do use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    resources: {
      en: {
        translations: translationEng
      },
      ru: {
        translations: translationRu
      }
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    react: {
      wait: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p']
    },

    detection: {
      // order and from where user language should be detected
      // for dev use en always
      order: [process.env.NODE_ENV === "production" ? 'navigator' : '']
    }

  });

export default i18n;
