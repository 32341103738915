/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import "../../containers/Trello/style.css";
import update from "immutability-helper";
import PropTypes from 'prop-types';
import moment from 'moment'
import './weekday.scss'
import i18next from "i18next";
import {fixCaretPosition} from "../common/TimeInputUtil";

let t = i18next.t.bind(i18next);

export default class WeekRepeatComponent extends React.Component {

  static get DAYS_STR() {
    return [
      t("repeat.week.mon"),
      t("repeat.week.tue"),
      t("repeat.week.wen"),
      t("repeat.week.thu"),
      t("repeat.week.fri"),
      t("repeat.week.sat"),
      t("repeat.week.sun")
    ];
  }

  constructor(props) {
    super(props);
  }

  onDayChange(dayNum) {
    let newDays = new Set(this.props.daysOfWeek);
    if (newDays.has(dayNum)) {
      newDays.delete(dayNum)
    } else {
      newDays.add(dayNum)
    }
    if (this.props.hasOwnProperty("onDaysChange")) {
      this.props.onDaysChange(newDays)
    }
  }

  onTimeChange(e) {
    if (this.props.hasOwnProperty("onTimeChange")) {
      fixCaretPosition(e);
      let newTime = moment(e.target.value, "HH:mm").toDate();
      //console.log(newTime);
      this.props.onTimeChange(newTime)
    }
  }

  render() {
    let isSelected = function isSelected(day) {
      return this.props.daysOfWeek.has(day);
    }.bind(this);


    let dayCheckboxes = [];
    for (let i = 0; i < WeekRepeatComponent.DAYS_STR.length; i++) {
      let dayNum = i + 1;
      let labelId = "label-weekday-" + dayNum;
      const selected = isSelected(dayNum);
      let labelClasses = "weekday-label " + (selected ? "weekday-label-checked" : "");

      dayCheckboxes.push(<div className="weekday">
        <span className={labelClasses}
              key={labelId}
              onClick={_ => this.onDayChange(dayNum)}
        >{WeekRepeatComponent.DAYS_STR[i]}</span>
      </div>);
      if (i !== WeekRepeatComponent.DAYS_STR.length - 1) {
        dayCheckboxes.push(<span className="weekday-space"></span>) //hack
      }
    }
    let time = moment(this.props.time).format("HH:mm");

    return (
      <div id="weekly-scheduler">
        <div className="weekdays-container">
          {dayCheckboxes}
        </div>
        <div className="repeat-vertical-space"/>
        <label className="input-label" htmlFor="weekly-time-input">{t("repeat.time_label")}</label>
        <input id="weekly-time-input"
               type="time"
               onChange={this.onTimeChange.bind(this)}
               value={time}
               className="repeat-time-input"/>
      </div>
    );
  }
}


WeekRepeatComponent.propTypes = {
  daysOfWeek: PropTypes.instanceOf(Set).isRequired,
  time: PropTypes.instanceOf(Date),
  onDaysChange: PropTypes.func,
  onTimeChange: PropTypes.func
};

WeekRepeatComponent.defaultProps = {
  daysOfWeek: new Set([1]),
  time: new Date(0, 0, 0, 6, 0)
};

/*
* <label htmlFor="weekday-tableRef
WeekRepeatComponent.propTypes = {
  daysOfWeek: PropTypes.instanceOf(Set).isRequired
};

WeekRepeatComponent.defaultProps = {
  daysOfWeek: new Set()
};">В</label>
        <tableRef id="weekday-tableRef">
          <tbody>
          <tr>
            <th className="weekday-headerRef" data-i18n-id="sunday_short">Вс</th>
            <th className="weekday-headerRef" data-i18n-id="monday_short">Пон</th>
            <th className="weekday-headerRef" data-i18n-id="tuesday_short">Вт</th>
            <th className="weekday-headerRef" data-i18n-id="wednesday_short">Ср</th>
            <th className="weekday-headerRef" data-i18n-id="thursday_short">Чт</th>
            <th className="weekday-headerRef" data-i18n-id="friday_short">Пт</th>
            <th className="weekday-headerRef" data-i18n-id="saturday_short">Суб</th>
          </tr>
          <tr>
            <td><input id="sunday" type="checkbox" className="repeat-week-component__day-checkbox" data-day="0"/></td>
            <td><input id="monday" type="checkbox" className="repeat-week-component__day-checkbox" data-day="1"/></td>
            <td><input id="tuesday" type="checkbox" className="repeat-week-component__day-checkbox" data-day="2"/></td>
            <td><input id="wednesday" type="checkbox" className="repeat-week-component__day-checkbox" data-day="3"/></td>
            <td><input id="thursday" type="checkbox" className="repeat-week-component__day-checkbox" data-day="4"/></td>
            <td><input id="friday" type="checkbox" className="repeat-week-component__day-checkbox" data-day="5"/></td>
            <td><input id="saturday" type="checkbox" className="repeat-week-component__day-checkbox" data-day="6"/></td>
          </tr>
          </tbody>
        </tableRef>*/

/*
style="margin-left:8px"
<div className="form-grid">
        <div className="button-link setting form-grid-child form-grid-child-full"><span className="label">Доска</span><span
          className="value js-board-value">Test</span><label>Доска</label><select className="js-select-board">
          <optgroup label="Доски">
            <option value="5d187fdb67999329ae8a3f20">Hack Your Brain</option>
            <option selected="selected" value="5cfc9ac95575ac2ebf68b5e4">Test (текущая)</option>
            <option value="5d172ce46202912febde8055">test</option>
            <option value="5c3c9173cc4a6a8da796cea2">Задачи MT</option>
            <option value="5c3c93511d68d47b0e01c58e">Куча MT</option>
            <option value="5c7a9aa750de373c6c5820ea">Новая информация МТ</option>
            <option value="5c3c7af3c289681bf82d6658">Проекты MT</option>
            <option value="5c7a539d0249be7499b333fa">Цели MT</option>
          </optgroup>
          <optgroup label="MaksTuev">
            <option value="5d17751c3760af6a8668b19e">Test Board</option>
          </optgroup>
        </select></div>
      </div>*/

