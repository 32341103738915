/*
 * FeaturePage
 *
 * List all the features
 */
import React, {PureComponent} from 'react';
import TrelloService from "../../service/TrelloService";
import RepeatService from "../../service/RepeatService";
import BoardStatusService from "../../service/BoardStatusService";
import '../common.scss'
import PropTypes from "prop-types";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";

export default class CheckboxComponent extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.repeatService = RepeatService.getInstance(this.trelloService);
    this.boardStatusService = new BoardStatusService(this.trelloService, this.repeatService);
  }

  componentDidMount() {

  }


  onCheckedChange(e) {
    this.props.onCheckedChange(e.target.checked);
  }



  render() {
    return <FormControlLabel
      control={
        <StyledCheckbox
          disableRipple
          checked={this.props.checked}
          onChange={this.onCheckedChange.bind(this)}
          value={this.props.text}/>
      }
      label={this.props.text}
    />
  }
}

CheckboxComponent.propTypes = {
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheckedChange: PropTypes.func
};

const StyledCheckbox = withStyles({
  root: {
    color: "#DFE1E6",
    '&$checked': {
      color: "#2E79BA",
    },
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  checked: {},
})(props => <Checkbox disableRipple color="default" {...props} />);
