/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from "prop-types";
import DaysOfMonthSelectComponent from "./DaysOfMonthSelectComponent";
import cronstrue from 'cronstrue';

import "./repeat.scss"
import Tippy from "@tippy.js/react";

import i18next from "i18next";
import {Trans} from "react-i18next";
import CheckboxComponent from "../common/CheckboxComponent";
import moment from "moment";

let t = i18next.t.bind(i18next);

export default class NotifyRepeatComponent extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

  }

  onChangeHours(event) {
    this.props.onHoursChange(event.target.value)
  }

  onChangeMinutes(event) {
    this.props.onMinutesChange(event.target.value)
  }

  onChangeEnabled(checked) {
    this.props.onEnabledChange(checked)
  }

  render() {
    const enabled = this.props.notificationEnabled;

    let content = enabled
      ? <div className="notify-content">
        <span className="notify-in-time-title">{t("repeat.notify.in_time_title")}</span>
        <div className="notify-in-time-inputs">
          <div className="notify-hours-input-block">
            <label className="input-label" htmlFor="notify-hours-input">{t("repeat.notify.hours")}</label>
            <input id="notify-hours-input"
                   className="notify-input"
                   type="number"
                   min="0"
                   required
                   onChange={this.onChangeHours.bind(this)}
                   value={this.props.hours}/>
          </div>
          <div className="notify-minutes-input-block">
            <label className="input-label" htmlFor="notify-minutes-input">{t("repeat.notify.minutes")}</label>
            <input id="notify-minutes-input"
                   className="notify-input"
                   type="number"
                   min="0"
                   required
                   onChange={this.onChangeMinutes.bind(this)}
                   value={this.props.minutes}/>
          </div>
        </div>
        <span className="notify-date">{this.getNotifyDateText()}</span>
      </div>
      : null;

    return <div className="email-notify-block">
      <div className="notify-checkbox-container">
        <CheckboxComponent text={t("repeat.notify.checkbox")}
                           checked={enabled}
                           onCheckedChange={(checked) => this.onChangeEnabled(checked)}/>
      </div>
      {this.getNeedHelpUI(enabled)}
      {content}
    </div>
  }

  getNeedHelpUI(enabled) {

    let notificationsLink = "https://help.trello.com/article/793-receiving-trello-notifications";
    let dueDateLink = "https://help.trello.com/article/794-adding-due-dates-to-cards"
    return <Tippy content={
        <div className="popup-multiline-container">
          <span className="popup-text">
            <Trans i18nKey="repeat.notify.help">
                Notifications work like standard trello <a
              href={dueDateLink}
              target="_blank">due date notifications</a>.<br/> Depending on your <a
              href={notificationsLink}
              target="_blank">settings</a>, you will receive it as desktop notifications, email or push notifications from Trello app on your mobile device.<br/><br/>When the due date is marked as complete (even via mobile push notification), card will be immediately moved to list "Done".
            </Trans>
          </span>
        </div>
      }
               trigger="click"
               placement="bottom-start"
               arrow="true"
               theme="light-border"
               interactive="true"
      >
        <span className="no-highlight-on-focus" id="notify-help">{t("repeat.notify.need_help")}</span>
      </Tippy>
  }

  getNotifyDateText() {
    let result = <span><br/></span>;
    if (this.props.creationDate) {
      let dateText = moment(this.props.creationDate)
        .add(this.props.hours, 'h')
        .add(this.props.minutes, 'm')
        .format("dddd, MMMM Do YYYY, h:mm a"); //todo_i18n
      result = `${t("repeat.notify.date_prefix")} ${dateText}`
    }
    return result
  }
}


NotifyRepeatComponent.propTypes = {
  notificationEnabled: PropTypes.bool.isRequired,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  creationDate: PropTypes.any, //maybe null if error
  onHoursChange: PropTypes.func.isRequired,
  onMinutesChange: PropTypes.func.isRequired,
  onEnabledChange: PropTypes.func.isRequired
};

/*
let daysOfWeekText = "All";
    let monthsText = "Jan, Feb";
    let daysOfMonthText = "From 1 to 14";
    let hoursText = "All";
    let minutesText = "All";

<tableRef>
          <tbody>
          <tr>
            <td>Days of week:</td>
            <td>{daysOfWeekText}</td>
          </tr>
          <tr>
            <td>Months</td>
            <td>{monthsText}</td>
          </tr>
          <tr>
            <td>Days of month</td>
            <td>{daysOfMonthText}</td>
          </tr>
          <tr>
            <td>Hours</td>
            <td>{hoursText}</td>
          </tr>
          <tr>
            <td>Minutes</td>
            <td>{minutesText}</td>
          </tr>
          </tbody>
        </tableRef>

*/
