/*
 * FeaturePage
 *
 * List all the features
 */
import React, {PureComponent} from 'react';
import TrelloService from "../../service/TrelloService";

import PropTypes from "prop-types";
import "./habitTracker.scss";
import Tippy from "@tippy.js/react";
import 'tippy.js/themes/light-border.css'
import AggregationTypeSelectComponent from "./AggregationTypeSelectComponent";
import {Grid, AutoSizer, CellMeasurerCache, CellMeasurer} from "react-virtualized";
import SelectComponent from "../common/SelectComponent";
import SuperRangeHabitComponent from "./SuperRangeHabitComponent";
import * as ReactDOM from "react-dom";
import {getCellWidth, getListRightPadding, getSuperRangeWidth} from "./HabitResProvider";


export default class HeaderHabitComponent extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.trelloService = TrelloService.getIframeInstance();
    this.state = {};
    this.rangesListRef = React.createRef();
  }

  componentDidMount() {
  }

  onScroll(scrollLeft) {
    this.rangesListRef.current.scrollToPosition({scrollLeft: scrollLeft})
  }

  renderSuperRange({columnIndex, key, style, parent}) {
    if (this.isPaddingCol(columnIndex)) {
      return <div className="list-padding-right" key={key}/>
    }

    return (
      <div style={style} id="super-range-list-item" key={key}>
        <SuperRangeHabitComponent
          superRange={this.props.superRanges[columnIndex]}
          aggregationType={this.props.aggregationType}
          highlightedCol={this.props.highlightedCol}
          highlightEventListener={this.props.highlightEventListener}/>
      </div>
    );
  }

  isPaddingCol(columnIndex) {
    return columnIndex === this.props.superRanges.length;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.aggregationType !== this.props.aggregationType) {
      //this.rangesListRef.current.recomputeGridSize() //todo_rfc wtf? why commented?
    }
  }

  render() {
    let content;
    content =
      <div id="header-content">
        <div id="header-left-block">
          <button id="header-settings-btn" onClick={this.props.openSettingsListener}><img
            id="header-settings-image"
            src={require('../../public/ic-settings.svg')}/></button>
          <div id="header-aggregation-selector">
            <AggregationTypeSelectComponent
              onChange={this.props.onAggregationTypeChange}
              selectedOptionValue={this.props.aggregationType}/>
          </div>
        </div>
        <div className="vertical-divider"/>
        <div id="header-right-block">
          <AutoSizer>
            {({width, height}) => (
              <Grid
                id="Header"
                ref={this.rangesListRef}
                height={height}
                width={width}
                columnCount={this.props.superRanges.length + 1} //+ padding
                columnWidth={({index: col}) => this.getWidth(col)}
                rowCount={1}
                rowHeight={height}
                cellRenderer={this.renderSuperRange.bind(this)}
                className="ranges-grid no-highlight-on-focus"
              />
            )}
          </AutoSizer>

        </div>
      </div>
    ;

    return content;
  }

  getWidth(col) {
    return this.isPaddingCol(col)
      ? getListRightPadding()
      : this.rangesNotEmpty() ? getSuperRangeWidth(this.props.superRanges[col]) : 0;
  }

  rangesNotEmpty() {
    return this.props.superRanges.length > 0;
  }
}

HeaderHabitComponent.propTypes = {
  aggregationType: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  superRanges: PropTypes.array.isRequired,
  /*
  {
    ranges: [{
        start: moment,
        end: moment
      }],
    firstRangeInd: int
  }*/
  onAggregationTypeChange: PropTypes.func.isRequired,
  openSettingsListener: PropTypes.func.isRequired,
  highlightedCol: PropTypes.number,
  highlightEventListener: PropTypes.func.isRequired,

};

HeaderHabitComponent.defaultProps = {
  superRanges: [],
};

